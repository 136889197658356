import { useCallback, useRef, createRef, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { isMobile, useMobileOrientation } from "react-device-detect";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { useSpring, config } from "react-spring";
import "./styles.scss";

export const BaseCard = ({ baseDataStateObj, detailsDataStateObj }) => {
  const drawer = useRef();
  const { isLandscape, isPortrait } = useMobileOrientation();
  const activeBaseCard = baseDataStateObj.state.array[baseDataStateObj.state.activeIndex];
  const cardElementsRef = useRef(baseDataStateObj.state.array.map(() => createRef()));

  // used for scroll position
  useEffect(() => {
    drawer.current = !drawer.current && document.getElementById("drawer");
  }, []);

  const [y, setY] = useSpring(() => ({
    config: config.gentle,
    y: 0,
    onChange: ({ value }) => {
      drawer.current.scrollTop = value.y;
    },
  }));

  useEffect(() => {
    if (activeBaseCard._id) {
      const index = baseDataStateObj.state.array.findIndex((cardElement) => cardElement._id === activeBaseCard._id);
      const offset = index === 0 ? 250 : 55;
      const scrollDestination = cardElementsRef.current.find((ref) => ref.current.id === activeBaseCard._id).current.offsetTop - offset;
      if (scrollDestination > 0) setY({ y: scrollDestination });
    }
  }, [detailsDataStateObj.isDetailsActive]);

  const basesToDisplay = detailsDataStateObj.isDetailsActive
    ? [baseDataStateObj.state.array[baseDataStateObj.state.activeIndex]]
    : baseDataStateObj.state.array;

  const handleContactSales = () => {
    document.dispatchEvent(new CustomEvent("ToggleCustomerSupport"));
    pushGTMAnalyticsEvent("Contact-Sales-Step-1-Click");
  };

  const handleSeeDetails = (base) => {
    detailsDataStateObj.setState({ isActive: !detailsDataStateObj.isDetailsActive, playSetType: base.animationDetails.playSetType });
    if (!detailsDataStateObj.isDetailsActive) {
      pushGTMAnalyticsEvent("See-Details-Step-1-Click");
    }
  };

  const handleCardClick = useCallback(
    (index) => {
      if (detailsDataStateObj.isDetailsActive) return;

      baseDataStateObj.setState(index);

      if (baseDataStateObj.state.array[index]._id === "clubhouse") {
        pushGTMAnalyticsEvent("Step1-Clubhouse-Card-Click");
      }
    },
    [detailsDataStateObj?.state?.activeIndex]
  );

  return basesToDisplay.map((base, index) => (
    <Col xs={6} md={isMobile && isPortrait ? 6 : 10} key={base._id} id={base._id} ref={cardElementsRef.current[index]} className="px-2 px-lg-0 mx-auto">
      <Card
        className={`mx-auto mb-3 ${base.customData.cardColor} ${
          baseDataStateObj.state.activeIndex === index || detailsDataStateObj.isDetailsActive ? "active" : ""
        }`}
        onClick={() => handleCardClick(index)}
      >
        <div className="d-flex frame mx-auto mb-1">
          {baseDataStateObj.state.activeIndex === index || detailsDataStateObj.isDetailsActive ? (
            <img className="checkMark" alt="check mark" src="/images/checkMark.svg" />
          ) : null}
          <Card.Img variant="top" className="playGroundImage mx-auto mt-1" src={base.previewImg} />
        </div>
        <Card.Body className="py-1">
          <Card.Title className="text-center">{base.title}</Card.Title>
          {detailsDataStateObj.isDetailsActive && <Card.Text>{isMobile ? base.mobileDescription : base.description}</Card.Text>}
          <div className={`d-flex justify-content-between ${!detailsDataStateObj.isDetailsActive && "mt-4"}`}>
            <button className={`mb-1 mx-2 ${base.customData.cardColor}`} onClick={() => handleContactSales()}>
              {" "}
              Contact Sales{" "}
            </button>
            <button className={`mb-1 mx-2 ${base.customData.cardColor}`} onClick={() => handleSeeDetails(base)}>
              {" "}
              {detailsDataStateObj.isDetailsActive ? "Back" : "See Details"}{" "}
            </button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  ));
};
