import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

export const HeadersBuildSection = ({ headerLabel, headerLineImgSrc, subheaderLabel }) => {
  return (
    <Row className="g-0">
      <Col className="d-flex flex-column">
        <div className="headers d-flex flex-column">
          <h2 className="text-center pt-3 mb-0">{headerLabel} </h2>
          {!isMobile && <img style={{ width: "100px" }} className="mt-1 mx-auto" alt="header underline" src={headerLineImgSrc} />}
          {subheaderLabel && <h6 className="text-center mt-1 mb-0">{subheaderLabel} </h6>}
        </div>
      </Col>
    </Row>
  );
};

HeadersBuildSection.propTypes = {
  headerLabel: PropTypes.string.isRequired,
  headerLineImgSrc: PropTypes.string,
  subheaderLabel: PropTypes.string,
};
