import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Badge, ListGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { isMobile } from "react-device-detect";
import XComponentsManager from "../XComponentsManager";
import { sizeInfoAtom } from "../../../data/atoms/SizeInfoData";
import { itemsAtom } from "../../../data/atoms/ItemsData";
import { update_items_obj } from "../../../data/atoms/ItemsData";
import { useAtom } from "jotai";
import Row from "react-bootstrap/Row";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ItemCard } from "../../CardElement/ItemCard";
import "../../../styles.scss";

const LeftComponentDrawer = observer(({ mobX }) => {
  const [sizeInfo] = useAtom(sizeInfoAtom);
  const [, updateItems] = useAtom(update_items_obj);
  const matches = useMediaQuery(`(min-width: 769px)`); // returns bool

  const onHide = useCallback(() => {
    mobX.hideManager();
  }, []);

  const handleReset = useCallback(() => {
    updateItems("");
  }, []);

  if (!mobX.show || !mobX.component || sizeInfo.isActive) {
    return null;
  }

  // sets the drawer position depending on viewport size
  const handleDrawerPosition = () => {
    if (matches) return { top: 0, left: 0, height: "100vh", width: "325px" };
    else return { bottom: 0, height: "55%", width: "100%" };
  };

  return (
    <div className="bg-white LeftComponentDrawer" style={handleDrawerPosition()}>
      <div className="d-flex flex-column">
        <h2 className="text-center pt-3 mb-0" style={{ fontFamily: "Acme" }}>
          {`Customize ${mobX.product._id}`}
        </h2>
        {!isMobile && <img style={{ width: "100px" }} className="mx-auto" alt="header underline" src="/images/headerLine.svg" />}
      </div>
      <div className="py-2 text-center px-3">
        <ComponentSelector activeComponentId={mobX.component.nodeId} mobX={XComponentsManager} {...mobX.product} />
      </div>
      <div className="px-3">
        <h5 className="d-flex justify-content-center mx-auto mb-2">{`${mobX.component.label} Options`}</h5>
        <Row>
          {mobX.component.items.map((item, i) => (
            <ItemCard
              index={i}
              nodeId={mobX.component.nodeId}
              defaultIndex={mobX.component.defaultIndex} // added dynamically in ComponentsSelector.js
              key={`item-${mobX.component.nodeId}-${i}`}
              {...item}
            />
          ))}
        </Row>
      </div>
    </div>
  );
});

const ItemTableItem = ({ id, label, nodeId, index, defaultIndex }) => {
  const [itemsObj] = useAtom(itemsAtom);
  const [, updateItems] = useAtom(update_items_obj);

  const active = itemsObj?.[nodeId] == id || (index == defaultIndex && !itemsObj?.[nodeId]);

  const handleClick = useCallback(() => {
    let updatedItems = { ...itemsObj };
    updatedItems[nodeId] = id;
    updateItems(updatedItems);
  }, [id, nodeId, itemsObj]);

  return (
    <ListGroup.Item action active={active} onClick={handleClick} className="d-flex justify-content-between align-items-center">
      <div>
        <strong>{label}</strong>
      </div>
      {defaultIndex == index && <Badge className="ms-2">Default</Badge>}
    </ListGroup.Item>
  );
};

const ComponentSelector = ({ _id, components, activeComponentId, mobX }) => {
  const [, updateItems] = useAtom(update_items_obj);

  const onHide = useCallback(() => {
    mobX.hideManager();
  }, []);

  const handleReset = useCallback(() => {
    updateItems("");
  }, []);

  if (!components || components.length === 0) {
    return null;
  }

  // if component only has 1 item option, remove it from the list
  components = components.filter((component) => {
    return component.items.length > 1;
  });

  return (
    <div className="rounded pt-2 px-1 pb-1 mb-2">
      <div className="flex-column mb-2">
        <div className="d-flex align-items-center">
          <button className="headerButtons" onClick={onHide}>
            Back
          </button>
          <h4 className="my-auto flex-grow-1">Components</h4>
          <button className="headerButtons" onClick={handleReset}>
            Reset
          </button>
        </div>
      </div>

      {components.map((component) => (
        <ComponentSelectorItem key={`component-${component.nodeId}`} active={activeComponentId === component.nodeId} mobX={XComponentsManager} {...component} />
      ))}
    </div>
  );
};

const ComponentSelectorItem = observer(({ active, mobX, ...rest }) => {
  const { label, uri, componentIconActive, componentIconInactive } = rest;
  const imgSrc = active ? componentIconActive : componentIconInactive;

  const handleClick = useCallback(() => {
    mobX.setComponent({ component: rest });
  }, [uri]);

  return (
    <button onClick={handleClick} className={`py-2 px-3 m-1 component-button ${active ? `active-component` : `inactive-component`}`}>
      <img src={imgSrc} alt={active ? "Active" : "Inactive"} className="icon me-1" />
      {label}
    </button>
  );
});

export default LeftComponentDrawer;
