import { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { CircleButton } from "../CircleButton/CircleButton";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import EmbedController from "../../../embed/EmbedController";
import { Boop } from "../Animations/Boop";

export const WidgetCopyURL = () => {
  // content to show in overlay on btn click
  const tooltipContent = {
    onHover: "Copy play set to clipboard",
    onClick: "Copied! Feel free to share or save this link",
    placement: "bottom",
  };

  // animations
  const [styles, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));

  const fireAnimation = () => {
    animate({ to: { opacity: 1, scale: 1 } });
  };

  useEffect(() => {
    document.addEventListener("Scene2IsBeingRevealed", fireAnimation);
    document.addEventListener("Scene3IsBeingRevealed", fireAnimation);
    return () => {
      document.removeEventListener("Scene2IsBeingRevealed", fireAnimation);
      document.removeEventListener("Scene3IsBeingRevealed", fireAnimation);
    };
  }, []);

  const handleWidgetClick = () => {
    {
      navigator.clipboard.writeText(EmbedController.isEmbedded ? EmbedController.parentUrl : window.location.href);
    }
    pushGTMAnalyticsEvent("Copy-Playset-Click");
  };

  return (
    <Boop rotation={20} timing={200}>
      <animated.div style={styles} onClick={() => handleWidgetClick()} className="copyLinkButton m-2">
        <CircleButton circleColor={"rgba(5, 79, 174, 0.8)"} tooltipContent={tooltipContent} icon={"/images/iconCopyLink.svg"} />
      </animated.div>
    </Boop>
  );
};
