import React, { useState, useEffect } from "react";
import { Router } from "wouter";
import { useParamsFromHash, useHashLocation } from "./useHash";
import EmbedController from "../../../embed/EmbedController";
import BuildSectionStepperData from "../../data/atoms/BuildSectionStepperData";
import BaseData from "../../data/atoms/BaseData";
import SizeData from "../../data/atoms/SizeData";
import PlaysetData from "../../data/atoms/PlaysetData";
import ItemsData from "../../data/atoms/ItemsData";
import SizeInfoData from "../../data/atoms/SizeInfoData";
import playSystemsBaseData from "../../data/playSystemsBase.json";
import playSystemsSizes from "../../data/playSystemsSizes.json";
import products from "../../data/products.json";
import AlertSlackOfError from "../../../monitoring/AlertSlackOfError";

/**
 *
 * This component manages reading and writing hash data in the URL, which defines the global state data
 * Reads active id's from URL hash and passes them down to the global data managers
 * Writes URL whenever global data managers call function to update an activeId, which was passed down to them
 *
 */

// URL params
// Ex. /#/:style/:size/:playset/:items
export const styleParam = "style";
export const sizeParam = "size";
export const playsetParam = "playset";
export const itemsParam = "items";

export const urlPatterns = [
  `#/:${styleParam}`,
  `#/:${styleParam}/:${sizeParam}`,
  `#/:${styleParam}/:${sizeParam}/:${playsetParam}`,
  `#/:${styleParam}/:${sizeParam}/:${playsetParam}/:${itemsParam}`,
];

// consumes the URL hash and creates data we need
export const useUrlParams = () => {
  const urlParams = useParamsFromHash();
  let paramsObj = {};
  // set defaults
  paramsObj[styleParam] = { id: "castle", index: 0 };
  paramsObj[sizeParam] = null;
  paramsObj[playsetParam] = null;
  paramsObj[itemsParam] = null;
  // edit params template according to current hash
  if (urlParams[styleParam]) paramsObj[styleParam] = { id: urlParams[styleParam], index: getActiveIndex(playSystemsBaseData, urlParams[styleParam]) };
  if (urlParams[sizeParam]) paramsObj[sizeParam] = { id: urlParams[sizeParam], index: getActiveIndex(playSystemsSizes, urlParams[sizeParam]) };
  if (urlParams[playsetParam]) paramsObj[playsetParam] = { id: urlParams[playsetParam], index: getActiveIndex(products, urlParams[playsetParam]) };
  if (urlParams[itemsParam]) paramsObj[itemsParam] = uriToJSONString(urlParams[itemsParam]);
  return paramsObj;
};

const getActiveIndex = (arr, targetId) => {
  for (let i = 0; i < arr.length; i++) {
    const element = arr[i];

    if (element?._id === targetId) {
      return i; // Found the target object, return its index
    }

    if (Array.isArray(element)) {
      // If the element is an array, recursively search within it
      const index = getActiveIndex(element, targetId);
      if (index !== -1) {
        return index; // Return the index if found in the nested array
      }
    }
  }

  return -1; // Object with targetId not found in the array
};

let hasSlackBeenAlerted = false;
// converts a URI to a json string or null if it's invalid json
function uriToJSONString(uri) {
  try {
    var stringUri = decodeURIComponent(uri);
    var objectUri = JSON.parse(stringUri);
    if (objectUri && typeof objectUri === "object") return stringUri;
  } catch (e) {
    console.log("uri", uri);
    if (!hasSlackBeenAlerted) {
      AlertSlackOfError("uriToJSONString() in UrlDataController.js", `Resetting invalid URI to null: ${uri}`);
      hasSlackBeenAlerted = true;
      alert("The URL you input was incorrectly formatted. To prevent an error, we've reset the configuration (URL)");
    }
  }
  return null;
}

// helper to endcode and decode activeIds for URL
export function jsonToURI(json) {
  return json ? encodeURIComponent(JSON.stringify(json)) : "";
}

export function UrlDataController({ children }) {
  // consume URL hash and get data we need from it
  const [hashLocation, setHashLocation] = useHashLocation();
  const paramsObj = useUrlParams();

  /**
   *
   * stepper
   *
   */

  function update_buildSectionStepper(newIndex) {
    if (newIndex === 0) updateURL(styleParam, paramsObj[styleParam]?.index || 0, newIndex);
    else if (newIndex === 1) updateURL(sizeParam, paramsObj[sizeParam]?.index || 0, newIndex);
    else if (newIndex === 2) updateURL(playsetParam, paramsObj[playsetParam]?.index || 0, newIndex);
  }

  /**
   *
   * style
   *
   */

  const update_style_activeId_inURL = (newIndex) => {
    updateURL(styleParam, newIndex);
  };

  /**
   *
   * size
   *
   */

  function update_size_activeId_inURL(newIndex) {
    updateURL(sizeParam, newIndex);
  }

  /**
   *
   * playsets
   *
   */

  const update_playset_activeId_inURL = (newIndex) => {
    updateURL(playsetParam, newIndex);
  };

  /**
   *
   * items
   *
   */

  const update_items_activeIds_inURL = (newIdObj) => {
    updateURL(itemsParam, newIdObj);
  };

  /**
   *
   *
   * Helper to update URL hash with new active id
   *
   *
   */

  const updateURL = (paramToUpdate, newValue, targetStepIndex) => {
    if (targetStepIndex === undefined) targetStepIndex = 2; // don't want to limit any if it's not a step change

    let hashPath = "#/";

    // style
    if (paramsObj[styleParam]?.id || paramToUpdate === styleParam) {
      hashPath += `${paramToUpdate === styleParam ? playSystemsBaseData[newValue]._id : paramsObj[styleParam].id}/`;
    }

    // size
    if (targetStepIndex > 0 && (paramsObj[sizeParam]?.id || paramToUpdate === sizeParam)) {
      hashPath += `${paramToUpdate === sizeParam ? playSystemsSizes[paramsObj[styleParam].index][newValue]._id : paramsObj[sizeParam].id}/`;
    }

    // playset
    if (targetStepIndex > 1 && (paramsObj[playsetParam]?.id || paramToUpdate === playsetParam)) {
      hashPath += `${
        paramToUpdate === playsetParam ? products[paramsObj[styleParam].index][paramsObj[sizeParam].index][newValue]._id : paramsObj[playsetParam].id
      }/`;
    }

    // items
    if (targetStepIndex > 1 && paramToUpdate !== playsetParam && (paramsObj[itemsParam] || paramToUpdate === itemsParam)) {
      hashPath += `${paramToUpdate === itemsParam ? jsonToURI(newValue) : paramsObj[itemsParam]}`;
    }

    /**
     * update the URL
     */
    if (hashLocation != hashPath) {
      // if embedded, update parent URL hash, which will reactivly update this embed URL hash (see code below)
      if (EmbedController.isEmbedded) EmbedController.sendHashChange(hashPath);
      else setHashLocation(hashPath);
    }
  };

  /**
   *
   *
   * If experience is embedded in parent site,
   * we send hash update requests to the parent
   * then react to hash updates from the parent by copying its hash here in the embed
   *
   */
  const [parentHashLocation, setParentHashLocation] = useState(window._tt?.initialParentHash);

  // setup listeners to react to parent site if we're embedded
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      // setup callback for when parent hash is updated
      EmbedController.setHashChangeCallback((hash) => {
        setParentHashLocation(hash);
      });
    }
  }, []);

  // when parentHashLocation is updated, we update the embed's hash location, which controls the experience
  useEffect(() => {
    if (EmbedController.isEmbedded && parentHashLocation && parentHashLocation != hashLocation) {
      setHashLocation(parentHashLocation);
    }
  }, [parentHashLocation]);

  // when the browser back or forward btn's are used the hashLocation changes but parentHashLocation doesn't so we need to send the update to the parent
  // NOTE: I'm aware this is backwards compared to the rest of our URL logic (updates should be passed from parent to embed) but that would require us to handle the embedded experience and standalone's URL logic differently, which is less ideal
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      // don't send duplicate updates (when embed hash and parent hash are equal) and don't update if experience is just starting (hashLocation is #/)
      if (hashLocation && hashLocation != parentHashLocation) {
        EmbedController.sendHashChange(hashLocation);
      }
    }
  }, [hashLocation]);

  return (
    <Router basePath={"/"} hook={useHashLocation}>
      <BuildSectionStepperData urlParamsObj={paramsObj} updateUrl={update_buildSectionStepper} />
      <BaseData urlParamsObj={paramsObj} updateUrl={update_style_activeId_inURL} />
      <SizeData urlParamsObj={paramsObj} updateUrl={update_size_activeId_inURL} />
      <PlaysetData urlParamsObj={paramsObj} updateUrl={update_playset_activeId_inURL} />
      <ItemsData urlParamsObj={paramsObj} updateUrl={update_items_activeIds_inURL} />
      <SizeInfoData />
      {children}
    </Router>
  );
}
