import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import products from "../products.json";
import { buildSectionStepperAtom } from "./BuildSectionStepperData";
import { isNumber } from "lodash";
import { playsetParam } from "../../components/Routing/UrlDataController";

export const playsetAtom = atom({
  array: products,
  activeIndex: 0,
});

export const update_playset_index = atom(null);

export default function PlaysetData({ urlParamsObj, updateUrl }) {
  const [playset, setPlayset] = useAtom(playsetAtom);
  const [activeIndex, setActiveIndex] = useAtom(update_playset_index);

  // handle request from children to update the activeIndex (url)
  useEffect(() => {
    if (isNumber(activeIndex) && activeIndex !== playset.activeIndex) {
      updateUrl(activeIndex);
      setActiveIndex(null);
    }
  }, [activeIndex]);

  useEffect(() => {
    // update state when url changes
    let newIndex = urlParamsObj[playsetParam]?.index;
    if (isNumber(newIndex) && newIndex !== playset.activeIndex) {
      setPlayset({ array: products, activeIndex: newIndex });
    }
  }, [urlParamsObj]);

  // Reset activeIndex to 0 when user goes back to step 1 or 2
  const [buildSectionStepper] = useAtom(buildSectionStepperAtom);
  useEffect(() => {
    if (buildSectionStepper.activeIndex === 0 || buildSectionStepper.activeIndex === 1) setPlayset({ array: products, activeIndex: 0 });
  }, [buildSectionStepper.activeIndex]);

  // useEffect(() => {
  //   console.log("&&& playset UPDATE", playset.activeIndex);
  // }, [playset]);

  return null;
}
