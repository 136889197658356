import React, { useState, useEffect, useRef } from "react";
import { SpinControls } from "./SpinControls";
import { useThree } from "@react-three/fiber";
import { AutoRotatePlatform } from "./Animations/AutoRotatePlatform";
import { LerpModelPosition } from "./Animations/LerpModelPosition";
import { SizeInfoControls } from "./SizeInfoControls";
import { useAssetLoader } from "./AssetSystem3d";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const SizesSceneController = ({ baseData, sizeData, buildSectionStepperActiveIndex }) => {
  // scene states
  const isSceneActive = buildSectionStepperActiveIndex === 1;
  const hasSceneBeenReachedOrPassed = buildSectionStepperActiveIndex > 0;
  const isExperienceStartingOnThisSceneRef = useRef(true);
  const [areModelsLoaded, setAreModelsLoaded] = useState(false);

  // CAMERA
  const camera = useThree(({ camera }) => camera);
  const setCameraTransform = () => {
    camera.rotation.set(0, 0, 0);
    camera.position.set(0, 1.6, 13);
  };

  // CASTLE MODEL
  const castleModelUri = sizeData.array[0][0].playSystemModel;
  const [castleGltf, setCastleGltf] = useState();
  const castleGltfRef = useRef();

  // CLUBHOUSE MODEL
  const clubhouseModelUri = sizeData.array[1][0].playSystemModel;
  const [clubhouseGltf, setClubhouseGltf] = useState();
  const clubhouseGltfRef = useRef();

  // ACTIVE GLTF
  const [activeGltf, setActiveGltf] = useState();
  const [activeGltfName, setActiveGltfName] = useState();
  const activeDataObj = sizeData.array[baseData.activeIndex][sizeData.activeIndex];

  // LOADING SCREEN
  const tellLoadingScreenImReady = () => {
    if (!isSceneActive) return;
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("ItemAssetsLoaded"));
    }, 500);
  };

  // SCENE ENTRANCE AND EXIT
  useEffect(() => {
    setTimeout(() => handleSceneChange(), 100);
  }, [buildSectionStepperActiveIndex]);

  async function handleSceneChange() {
    // entrance
    if (isSceneActive) {
      setCameraTransform();
      await loadScene();
      tellLoadingScreenImReady();
    }
    // exit or if experiences starts on another scene
    else {
      isExperienceStartingOnThisSceneRef.current = false;
    }
  }

  async function loadScene() {
    await loadSizesModels();
    setAreModelsLoaded(true);
  }

  // GLTF loader
  const getAsset = useAssetLoader();
  async function loadSizesModels() {
    var castleGltf, clubhouseGltf;
    if (baseData.activeIndex === 0) {
      castleGltf = await getAsset(castleModelUri);
      setCastleGltf(castleGltf);
    } else {
      clubhouseGltf = await getAsset(clubhouseModelUri);
      setClubhouseGltf(clubhouseGltf);
    }
  }

  // switch the active model depending upon which playset has been chosen in UI
  useEffect(() => {
    if (!areModelsLoaded) return;

    // castle
    if (baseData.activeIndex === 0 && castleGltf) {
      setActiveGltfName("castle");
      setActiveGltf(castleGltf.scene);
    }
    // clubhouse
    else if (baseData.activeIndex === 1 && clubhouseGltf) {
      setActiveGltfName("clubhouse");
      setActiveGltf(clubhouseGltf.scene);
    }
  }, [baseData.activeIndex, sizeData.activeIndex, castleGltf, clubhouseGltf, areModelsLoaded]);

  // loading screen is disappearing and revealing scene
  useEffect(() => {
    document.addEventListener("Scene2IsBeingRevealed", handleSceneBeingRevealed);
    return () => {
      document.removeEventListener("Scene2IsBeingRevealed", handleSceneBeingRevealed);
    };
  }, []);

  function handleSceneBeingRevealed() {
    if (!isExperienceStartingOnThisSceneRef.current && hasSceneBeenReachedOrPassed && isSceneActive && !window.isProductTourActive) {
      pushGTMAnalyticsEvent("Step2-Is-Being-Revealed");
    }
  }

  if (!hasSceneBeenReachedOrPassed) return null;

  return (
    <>
      {castleGltf && <primitive visible={isSceneActive && activeGltfName === "castle"} ref={castleGltfRef} object={castleGltf.scene} />}
      {clubhouseGltf && <primitive visible={isSceneActive && activeGltfName === "clubhouse"} ref={clubhouseGltfRef} object={clubhouseGltf.scene} />}

      {/* animations */}
      {isSceneActive && activeGltf && activeDataObj && (
        <>
          {/* move the all-playsets-group to focus on active playset */}
          <LerpModelPosition activeGltf={activeGltf} lerpPosition={activeDataObj.animationDetails.lerpPosition} />

          {/* hide or reveal size info */}
          <SizeInfoControls activeGltf={activeGltf} />

          <AutoRotatePlatform activeGltf={activeGltf} animationDetails={activeDataObj.animationDetails} />
          <SpinControls activePlaySystemType={activeDataObj.animationDetails.playSetType} activeGltf={activeGltf} />
        </>
      )}
      {/* ^^^ animations ^^^ */}
    </>
  );
};
