import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { buildSectionStepperAtom } from "./BuildSectionStepperData";
import { itemsParam } from "../../components/Routing/UrlDataController";

export const itemsAtom = atom(null);
export const update_items_obj = atom(undefined);

export default function ItemsData({ urlParamsObj, updateUrl }) {
  const [, setItems] = useAtom(itemsAtom);
  const [updatedObj] = useAtom(update_items_obj);

  // handle request from children to update the items (url)
  useEffect(() => {
    if (updatedObj !== undefined) updateUrl(updatedObj);
  }, [updatedObj]);

  useEffect(() => {
    // update state when url changes
    let newObj = JSON.parse(urlParamsObj[itemsParam]);
    setItems(newObj);
  }, [urlParamsObj]);

  // Reset items when user goes back to step 1 or 2
  const [buildSectionStepper] = useAtom(buildSectionStepperAtom);
  useEffect(() => {
    if (buildSectionStepper.activeIndex === 0 || buildSectionStepper.activeIndex === 1) setItems(null);
  }, [buildSectionStepper.activeIndex]);

  // useEffect(() => {
  //   console.log("&&& items UPDATE", items);
  // }, [items]);

  return null;
}
