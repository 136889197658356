import { useEffect, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useThree } from "@react-three/fiber";
import { sizeInfoAtom } from "../../data/atoms/SizeInfoData";
import { buildSectionStepperAtom } from "../../data/atoms/BuildSectionStepperData.js";
import { useAtom } from "jotai";
import { usePrevious } from "../../hooks/usePrevious";

export const SizeInfoControls = ({ activeGltf }) => {
  const scene = useThree(({ scene }) => scene);
  const camera = useThree(({ camera }) => camera);
  const [sizeInfo] = useAtom(sizeInfoAtom);
  const [buildSectionStepper] = useAtom(buildSectionStepperAtom);
  const infoLabelsRef = useRef([]);
  const opacityChange = 0.7;

  useFrame(() => {
    // rotate size labels towards camera
    if (infoLabelsRef.current.length && sizeInfo.isActive) {
      infoLabelsRef.current.forEach((label) => {
        label.lookAt(camera.position);
      });
    }
  });

  useEffect(() => {
    if (!sizeInfo) return;

    const activeSizesGroup = activeGltf.getObjectByName(`${sizeInfo.playSetType}-sizes-group`);
    const activePlaySystemGroup = activeGltf.getObjectByName(`${sizeInfo.playSetType}-playset-group`);
    const activeSceneryGroup = activeGltf.getObjectByName(`${sizeInfo.playSetType}-platform-scenery-group`) || scene.getObjectByName(`platform-scenery-group`);

    if (activeSizesGroup) {
      infoLabelsRef.current = [];
      // collect size labels
      activeSizesGroup.traverse((child) => {
        if (child.name.includes("label")) {
          infoLabelsRef.current.push(child);
        }
      });
      // toggle size info labels
      activeSizesGroup.visible = !activeSizesGroup.visible;
    }

    if (activePlaySystemGroup) {
      // toggle opacity of playset meshes
      activePlaySystemGroup.traverse((child) => {
        if (child.isMesh) {
          child.material.transparent = true;
          if (child.userData.defaultOpacity)
            child.material.opacity = sizeInfo.isActive ? child.userData.defaultOpacity - opacityChange : child.userData.defaultOpacity;
          // this handles case of items that have been swapped out and don't have defaultOpacity set
          else child.material.opacity = sizeInfo.isActive ? 1 - opacityChange : 1;
        }
      });
    }

    if (activeSceneryGroup) {
      // toggle opacity of scenery meshes
      activeSceneryGroup.traverse((child) => {
        if (child.isMesh) {
          child.material.transparent = true;
          child.material.opacity = sizeInfo.isActive ? 1 - opacityChange : 1;
        }
      });
    }
  }, [sizeInfo]);

  const prevStepperActiveIndex = usePrevious(buildSectionStepper.activeIndex);
  // when activeGltf or scene changes...
  useEffect(() => {
    // scene2 to scene3 transition
    if (prevStepperActiveIndex === 1 && buildSectionStepper.activeIndex === 2) {
      // reset mesh opacity to 1 in case sizeDetails were active
      activeGltf.traverse((child) => {
        if (child.isMesh) child.material.opacity = 1;
      });
    }

    // every time activeGltf changes
    activeGltf.traverse((child) => {
      // default visibility of sizes to false
      if (child.name.includes("sizes-group")) {
        child.visible = false;
      }
      // set defaultOpacity of mesh
      if (child.isMesh && !child.userData.defaultOpacity) {
        child.userData.defaultOpacity = child.material.opacity;
      }
      // reset opacity to default
      if (child.isMesh && child.userData.defaultOpacity) child.material.opacity = child.userData.defaultOpacity;
    });
  }, [activeGltf, buildSectionStepper.activeIndex]);

  return null;
};
