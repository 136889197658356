/**Used to lock screen on desktop when in portrait mode */
import React, { useEffect } from "react";
import { isMobileOnly, useMobileOrientation } from "react-device-detect";
import "./styles.scss";

export const LockOrientation = ({ imgSrc, acceptedOrientation }) => {
  const { isLandscape, isPortrait } = useMobileOrientation();
  let isAcceptedOrientation;
  if (acceptedOrientation === "portrait") isAcceptedOrientation = isPortrait;
  else if (acceptedOrientation === "landscape") isAcceptedOrientation = isLandscape;
  else {
    isAcceptedOrientation = isPortrait;
    acceptedOrientation = "portrait";
  }

  // overlay to prevent users from viewing in landscape on mobile
  const [isLockOrientationVisible, setIsLockOrientationVisible] = React.useState(isMobileOnly && !isAcceptedOrientation);

  useEffect(() => {
    setIsLockOrientationVisible(!isAcceptedOrientation);
  }, [isAcceptedOrientation]);

  if (isLockOrientationVisible && isMobileOnly) {
    return (
      <div className="lockScreen d-flex justify-content-center flex-column align-items-center">
        <img src={imgSrc} />
        <h6>{`Please rotate your screen - this experience was made to be viewed in ${acceptedOrientation} orientation`}</h6>
      </div>
    );
  } else {
    return null;
  }
};
