import React, { useState, useEffect, useRef } from "react";

import { LinearProgress } from "@material-ui/core";

import "./styles.scss";

export const SceneLoadingScreen = ({ buildSectionStepperActiveIndex }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [itemLoadedCount, setItemLoadedCount] = useState(0);
  const [areEnvironmentAssetsLoaded, setAreEnvironmentAssetsLoaded] = useState(false);
  const [isSceneryLoaded, setIsSceneryLoaded] = useState(false); // scenery for step 2 and 3

  // we'll setup event listeners to know when all item's 3D assets have been loaded
  // then we'll make the loading screen invisible
  useEffect(() => {
    document.addEventListener("ItemAssetsLoaded", handleItemAssetsLoaded);
    document.addEventListener("EnvironmentAssetsLoaded", handleEnvironmentAssetsLoaded);
    document.addEventListener("SceneryLoaded", handleSceneryLoaded);
    return () => {
      document.removeEventListener("ItemAssetsLoaded", handleItemAssetsLoaded);
      document.removeEventListener("EnvironmentAssetsLoaded", handleEnvironmentAssetsLoaded);
      document.removeEventListener("SceneryLoaded", handleSceneryLoaded);
    };
  }, []);

  // setup timer for loading screen
  const [isMinTimerDone, _setIsMinTimerDone] = useState(true);
  const isMinTimerDoneRef = useRef(true);
  const setIsMinTimerDone = (newState) => {
    isMinTimerDoneRef.current = newState;
    _setIsMinTimerDone(newState);
  };

  // Whenever a new scene is visited, we want to show the loading screen while it's assets are loading
  useEffect(() => {
    setIsVisible(true);
    setItemLoadedCount(0);
    // Show loading screen for a minimum of 1000ms so animations and such can occur properly
    if (isMinTimerDoneRef.current) {
      setIsMinTimerDone(false);
      setTimeout(() => {
        setIsMinTimerDone(true);
      }, 1000);
    }
  }, [buildSectionStepperActiveIndex]);

  // checking if everything's loaded
  useEffect(() => {
    if (itemLoadedCount >= 1 && areEnvironmentAssetsLoaded && isMinTimerDoneRef.current) {
      if (buildSectionStepperActiveIndex > 0 && !isSceneryLoaded) return;
      setIsVisible(false);
      document.dispatchEvent(new CustomEvent("SceneIsBeingRevealed"));
      document.dispatchEvent(new CustomEvent(`Scene${buildSectionStepperActiveIndex + 1}IsBeingRevealed`));
    }
  }, [itemLoadedCount, areEnvironmentAssetsLoaded, isMinTimerDone, isSceneryLoaded]);

  function handleItemAssetsLoaded(e) {
    setItemLoadedCount((itemLoadedCount) => itemLoadedCount + 1);
  }

  function handleEnvironmentAssetsLoaded() {
    setAreEnvironmentAssetsLoaded(true);
  }

  function handleSceneryLoaded() {
    setIsSceneryLoaded(true);
  }

  return (
    <>
      {isVisible && (
        <div
          className="sceneLoadingScreen d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "white", width: "100%", height: "100%", position: "absolute", top: 0, zIndex: 10000 }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: "50%", textAlign: "center" }}>
            <img className="mb-5" src="/images/full-logo.svg" />
            <LinearProgress />
          </div>
        </div>
      )}
    </>
  );
};
