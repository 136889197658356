import { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { Boop } from "../Animations/Boop";
import { CircleButton } from "../CircleButton/CircleButton";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const WidgetToggleSizeInfo = ({ baseData, sizeData, isDetailsActive, setDetails }) => {
  // content to show in overlay on btn click
  const tooltipContent = {
    onHover: "See sizing info",
    placement: "bottom",
  };

  // animations
  const [styles, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));

  const fireAnimation = () => {
    animate({ to: { opacity: 1, scale: 1 } });
  };

  useEffect(() => {
    document.addEventListener("Scene2IsBeingRevealed", fireAnimation);
    document.addEventListener("Scene3IsBeingRevealed", fireAnimation);
    return () => {
      document.removeEventListener("Scene2IsBeingRevealed", fireAnimation);
      document.removeEventListener("Scene3IsBeingRevealed", fireAnimation);
    };
  }, []);

  const handleWidgetClick = () => {
    setDetails({ isActive: !isDetailsActive, playSetType: sizeData.array[baseData.activeIndex][sizeData.activeIndex].animationDetails.playSetType });

    if (!isDetailsActive) {
      pushGTMAnalyticsEvent("See-Playset-Size-Click");
    }
  };

  return (
    <Boop rotation={20} timing={200}>
      <animated.div style={styles} className="toggleSizeButton m-2" onClick={() => handleWidgetClick()}>
        <CircleButton circleColor={"rgba(5, 79, 174, 0.8)"} icon={"/images/iconRuler.svg"} tooltipContent={tooltipContent} />
      </animated.div>
    </Boop>
  );
};
