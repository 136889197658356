/** SpinControls are used to allow user to grab play system and rotate */

import { useEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";

export const SpinControls = ({ activePlaySystemType, activeGltf }) => {
  const currentPlatform = activeGltf.getObjectByName(`${activePlaySystemType}-platform-rotation-group`);
  const isSpinActiveRef = useRef(false);
  const prevMousePosRef = useRef(0);
  const currentMousePosRef = useRef(0);
  const gl = useThree(({ gl }) => gl);
  const canvasWidth = useRef(gl.domElement.clientWidth);

  useEffect(() => {
    gl.domElement.onpointerdown = beginTracking;
    gl.domElement.onpointermove = updateTracking;
    gl.domElement.onpointerup = endTracking;
    window.onresize = function () {
      canvasWidth.current = gl.domElement.clientWidth;
    };
  }, []);

  function beginTracking(e) {
    isSpinActiveRef.current = true;
    window.isUserRotatingPlatform = true;
  }

  function updateTracking(e) {
    if (!isSpinActiveRef.current) return;
    currentMousePosRef.current = e.x;
    if (!prevMousePosRef.current) prevMousePosRef.current = e.x;
  }

  function endTracking() {
    isSpinActiveRef.current = false;
    window.isUserRotatingPlatform = false;
    prevMousePosRef.current = 0;
    currentMousePosRef.current = 0;
  }

  useFrame(() => {
    if (isSpinActiveRef.current && currentPlatform) spinPlatform();
  });

  function spinPlatform() {
    var mousePosChange = currentMousePosRef.current - prevMousePosRef.current;
    var rotChange = (mousePosChange / canvasWidth.current) * 7.5; // 1 full rotation across the screen
    var newRotY = currentPlatform.rotation.y + rotChange;

    currentPlatform.rotation.set(0, newRotY, 0);

    prevMousePosRef.current = currentMousePosRef.current;
  }

  return null;
};
