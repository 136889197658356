import { useCallback } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import { useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { itemsAtom } from "../../data/atoms/ItemsData.js"
import { update_items_obj } from "../../data/atoms/ItemsData.js"

export const ItemCard = ({ id, label, nodeId, index, defaultIndex, thumbnail }) => {
  const [itemsObj] = useAtom(itemsAtom);
  const [, updateItems] = useAtom(update_items_obj);
  const active = itemsObj?.[nodeId] == id || (index == defaultIndex && !itemsObj?.[nodeId]);

  const handleClick = useCallback(() => {
    let updatedItems = { ...itemsObj };
    updatedItems[nodeId] = id;
    updateItems(updatedItems);
  }, [id, nodeId, itemsObj]);

  if (!thumbnail || thumbnail?.length < 1) return null;

  return (
    <Col xs={6} className="mx-auto my-2">
      <Card className="mx-auto componentCard" onClick={handleClick}>
        { active && <img className="checkMark" alt="check mark" src="/images/checkMark.svg" />}
        <div className="d-flex mx-auto">
          <ImgWithFallback className="d-flex mx-auto thumbnail" src={thumbnail[1]} fallback={thumbnail[1] || thumbnail[0]} />
        </div>
        { active &&
          <div>
            <h6 className="label p-1"> {label} </h6>
          </div>
        }
      </Card>
    </Col>
  );
};
