import { useFrame } from "@react-three/fiber";

export const AutoRotatePlatform = ({ activeGltf, animationDetails }) => {
  const activePlaySystem = activeGltf.getObjectByName(`${animationDetails.playSetType}-platform-rotation-group`);
  const SPEED = 0.25;

  const rotatePlatform = (state, deltaTime) => {
    if (window.isUserRotatingPlatform) return; // set in SpinControls.js
    activePlaySystem?.rotation.set(0, activePlaySystem?.rotation.y + deltaTime * SPEED, 0);
  };

  useFrame((state, deltaTime) => {
    rotatePlatform(state, deltaTime);
  });

  return null;
};
