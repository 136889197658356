import { useThree, useFrame } from "@react-three/fiber";
import * as THREE from "three";

export const BaseSetsCameraRotation = ({ cameraRotationAxis }) => {
  const { camera } = useThree();
  let interval = 0;

  useFrame(() => {
    if (!cameraRotationAxis) return null;

    interval += 1;
    rotateCamera();
  });

  // set the rotation of the quaternion
  const quaternionRotation = cameraRotationAxis ? new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(...cameraRotationAxis), Math.PI / 2) : null;

  // smoothly rotates camera to active play system
  const rotateCamera = () => {
    if (interval <= 50) camera.quaternion.slerp(quaternionRotation, 0.005 * (interval / 4));
  };

  return null;
};
