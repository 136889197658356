import { useEffect, useRef, useCallback, createRef, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { useSpring, config } from "react-spring";
import { isMobile, useMobileOrientation } from "react-device-detect";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Boop } from "../Animations/Boop";
import { observer } from "mobx-react";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const ProductCard = observer(({ mobX, productDataStateObj, detailsDataStateObj, baseDataActiveIndex, sizeDataActiveIndex }) => {
  const [playsetComponents, setPlaysetComponents] = useState([]);
  const { isPortrait } = useMobileOrientation();
  const drawer = useRef();
  const activeProductCard = productDataStateObj.state.array[baseDataActiveIndex][sizeDataActiveIndex][productDataStateObj.state.activeIndex];
  const cardElementsRef = useRef(productDataStateObj.state.array[baseDataActiveIndex][sizeDataActiveIndex].map(() => createRef()));

  const [y, setY] = useSpring(() => ({
    config: config.slow,
    y: 0,
    onChange: ({ value }) => {
      drawer.current.scrollTop = value.y;
    },
  }));

  // used for scroll position
  useEffect(() => {
    drawer.current = !drawer.current && document.getElementById("drawer");
    document.addEventListener("ComponentsUpdated", handleComponentsUpdated);

    return () => {
      document.removeEventListener("ComponentsUpdated", handleComponentsUpdated);
    };
  }, []);

  // scroll to active card when user returns from details
  useEffect(() => {
    if (activeProductCard._id) {
      const index = productDataStateObj.state.array[baseDataActiveIndex][sizeDataActiveIndex].findIndex(
        (cardElement) => cardElement._id === activeProductCard._id
      );
      // if its first card in the list, offset by 250px to account for header
      const offsetPixels = index === 0 ? 250 : 55;
      const scrollDestination = cardElementsRef.current.find((ref) => ref.current.id === activeProductCard._id).current.offsetTop - offsetPixels;
      if (Math.abs(scrollDestination) > 0) setY({ y: scrollDestination });
    }
  }, [detailsDataStateObj.isDetailsActive]);

  function handleComponentsUpdated(e) {
    let components = e.detail;
    setPlaysetComponents(components);
  }
  const productsToDisplay = detailsDataStateObj.isDetailsActive
    ? [productDataStateObj.state.array[baseDataActiveIndex][sizeDataActiveIndex][productDataStateObj.state.activeIndex]]
    : productDataStateObj.state.array[baseDataActiveIndex][sizeDataActiveIndex];

  const detailsHandler = (item, index) => {
    // return if user clicks "see details" of card that isn't active
    if (productDataStateObj.state.activeIndex !== index && !detailsDataStateObj.isDetailsActive) return;

    detailsDataStateObj.setState({ isActive: !detailsDataStateObj.isDetailsActive, playSetType: item.animationDetails.playSetType });
    pushGTMAnalyticsEvent("See-Details-Step-3-Click");
  };

  const customizePlaysetHandler = () => {
    if (playsetComponents.length === 0) return null;

    // use first component as default and add in the productData
    let playSetData = { ...playsetComponents[0], product: activeProductCard };
    playSetData.product.components = playsetComponents;

    mobX.showManager(playSetData);

    pushGTMAnalyticsEvent("Customize-Playset-Button-Click");
  };

  const handleBuyButton = () => {
    document.dispatchEvent(new CustomEvent("ToggleCustomerSupport"));

    pushGTMAnalyticsEvent("Buy-Button-Step-3-Click");
  };

  const handleCardClick = useCallback(
    (index, item) => {
      if (detailsDataStateObj.isDetailsActive || productDataStateObj.state.activeIndex === index || !item.playSystemModel) return;
      productDataStateObj.setState(index);
    },
    [productDataStateObj.state.activeIndex]
  );

  return productsToDisplay.map((item, index) => (
    <Col id={item._id} xs={6} ref={cardElementsRef.current[index]} md={isMobile && isPortrait ? 6 : 10} key={item._id} className="px-2 px-lg-0 mx-auto">
      <Card
        className={`mx-auto ${index === productsToDisplay.length - 1 ? "mb-5" : "mb-3"}  ${item.customData.cardColor} ${productDataStateObj.state.activeIndex === index || detailsDataStateObj.isDetailsActive ? "active" : ""
          } ${item.playSystemModel ? "" : "construction"}`}
        onClick={() => handleCardClick(index, item)}
      >
        <div className="d-flex frame mx-auto mb-1">
          {productDataStateObj.state.activeIndex === index || detailsDataStateObj.isDetailsActive ? (
            <img className="checkMark" alt="check mark" src="/images/checkMark.svg" />
          ) : null}
          <Card.Img
            variant="top"
            className={`mx-auto mt-1 ${item.playSystemModel ? "playGroundImage" : "constructionImg"}`}
            src={item.playSystemModel ? item.previewImg : "/images/constructionCardImg.svg"}
          />
        </div>
        <Card.Body className="py-1">
          <Card.Title className="text-center">{item.name}</Card.Title>
          {!item.playSystemModel && (
            <div className="text-center">
              <p>
                This playset is coming soon to our configurator, but <b>is available</b> in our catalog!
              </p>
            </div>
          )}

          <div className="d-flex justify-content-around">
            {detailsDataStateObj.isDetailsActive ? (
              <button
                className={`mb-1 ${item.customData.cardColor}`}
                onClick={() => detailsDataStateObj.setState({ isActive: !detailsDataStateObj.isDetailsActive, playSetType: item.animationDetails.playSetType })}
              >
                Back
              </button>
            ) : (
              productDataStateObj.state.activeIndex === index && (
                <div className="buttons-container">
                  <Boop boopType="scale" scale={1.1} timing={200}>
                    <OverlayTrigger
                      key={item._id}
                      placement="bottom"
                      overlay={
                        <Tooltip style={{ zIndex: isMobile ? -1 : 2 }} id={`tooltip-buy-${item._id}`}>
                          {" "}
                          Speak To A Sales Rep{" "}
                        </Tooltip>
                      }
                    >
                      <button className="customize-button" onClick={() => handleBuyButton()}>
                        Buy
                      </button>
                    </OverlayTrigger>
                  </Boop>
                  <Boop boopType="scale" scale={1.1} timing={200}>
                    <OverlayTrigger
                      key={item._id}
                      placement="bottom"
                      overlay={
                        <Tooltip style={{ zIndex: isMobile ? -1 : 2 }} id={`tooltip-customize-${item._id}`}>
                          Customize Playset
                        </Tooltip>
                      }
                    >
                      <button className="customize-button" onClick={() => customizePlaysetHandler()}>
                        Customize
                      </button>

                    </OverlayTrigger>
                  </Boop>
                  <Boop boopType="scale" scale={1.1} timing={200}>
                    <OverlayTrigger
                      key={item._id}
                      placement="bottom"
                      overlay={
                        <Tooltip style={{ zIndex: isMobile ? -1 : 2 }} id={`tooltip-details-${item._id}`}>
                          {" "}
                          See Playset Details{" "}
                        </Tooltip>
                      }
                    >
                      <button className="customize-button icon" onClick={() => detailsHandler(item, index)}>
                        <img
                          src="/images/info.svg"
                          alt="Details"
                        />
                      </button>
                    </OverlayTrigger>
                  </Boop>
                </div>
              )
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  ));
});
