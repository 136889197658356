import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { styleParam, sizeParam, playsetParam } from "../../components/Routing/UrlDataController";
import { isNumber } from "lodash";

const steps = ["Select Style", "Select Size", "Select Options"];

export const buildSectionStepperAtom = atom({
  steps,
  activeIndex: null,
});

export const update_buildSectionStepper_index = atom(null);

// get the activeIndex from the URL
const getActiveIndex = (urlParamsObj) => {
  if (!urlParamsObj) return null;
  else if (urlParamsObj[playsetParam]) return 2;
  else if (urlParamsObj[sizeParam]) return 1;
  else if (urlParamsObj[styleParam]) return 0;
  else return null;
};

export default function BuildSectionStepperData({ urlParamsObj, updateUrl }) {
  const [buildSectionStepper, setBuildSectionStepper] = useAtom(buildSectionStepperAtom);
  const [activeIndex, setActiveIndex] = useAtom(update_buildSectionStepper_index);

  // handle request from children to update the activeIndex (url)
  useEffect(() => {
    if (isNumber(activeIndex) && activeIndex !== buildSectionStepper.activeIndex) {
      updateUrl(activeIndex);
      setActiveIndex(null);
    }
  }, [activeIndex]);

  useEffect(() => {
    // handlers to set state if they don't sync up with the pathname
    let newIndex = getActiveIndex(urlParamsObj);
    if (isNumber(newIndex) && newIndex !== buildSectionStepper.activeIndex) {
      setBuildSectionStepper({ steps, activeIndex: newIndex });
    }
  }, [urlParamsObj]);

  // useEffect(() => {
  //   console.log("&&& stepper UPDATE", buildSectionStepper.activeIndex);
  // }, [buildSectionStepper]);

  return null;
}
