import Row from "react-bootstrap/Row";
import { BaseCard } from "./BaseCard";
import { SizeCard } from "./SizeCard";
import { ProductCard } from "./ProductCard";
import "./styles.scss";

export const CardElement = (props) => {
  const [isBaseCardActive, isSizeCardActive, isProductCardActive] = cardFactory(props.buildSectionStepperActiveIndex);

  return (
    <Row className="g-0">
      {isBaseCardActive && <BaseCard baseDataStateObj={props.baseDataStateObj} detailsDataStateObj={props.detailsDataStateObj} />}
      {isSizeCardActive && (
        <SizeCard
          sizeDataStateObj={props.sizeDataStateObj}
          baseDataActiveIndex={props.baseDataStateObj.state.activeIndex}
          detailsDataStateObj={props.detailsDataStateObj}
        />
      )}
      {isProductCardActive && (
        <ProductCard
          productDataStateObj={props.productDataStateObj}
          mobX={props.mobX}
          baseDataActiveIndex={props.baseDataStateObj.state.activeIndex}
          sizeDataActiveIndex={props.sizeDataStateObj.state.activeIndex}
          detailsDataStateObj={props.detailsDataStateObj}
        />
      )}
    </Row>
  );
};

// returns which card type is active
const cardFactory = (buildSectionStepperActiveIndex) => {
  switch (buildSectionStepperActiveIndex) {
    case 0: //baseCards
      return [true, false, false];
    case 1: //sizeCards
      return [false, true, false];
    case 2: //productCards
      return [false, false, true];
    default:
      console.log("CardElment.jsx error");
  }
};
