import { Features } from "./Features";
import { Specs } from "./Specs";
import "./styles.scss";

export const Details = ({ stepIndex, detailData, featuresIsActive, specsIsActive }) => {
  return (
    <div className="Details">
      <Features isActive={featuresIsActive} detailData={detailData} />
      <Specs stepIndex={stepIndex} isActive={specsIsActive} detailData={detailData} />
    </div>
  );
};
