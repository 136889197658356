import { useState, useEffect } from "react";
import { CircleButton } from "../CircleButton/CircleButton";
import { useForm } from "react-hook-form";
import { useSpring, animated } from "react-spring";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Boop } from "../Animations/Boop";
import SendMessageToSlack from "../../../monitoring/SendMessageToSlack";
import AlertSlackOfError from "../../../monitoring/AlertSlackOfError";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { isMobile } from "react-device-detect";
import EmbedController from "../../../embed/EmbedController";
import "./styles.scss";

export const WidgetCustomerSupport = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();
  const [isOpen, setOpen] = useState(false);
  const [isCopyUrlClicked, setCopyUrlClicked] = useState(false);

  const countries = {
    US: "United States",
    BY: "Belarus",
    CA: "Canada",
    IN: "India",
    IT: "Italy",
    JP: "Japan",
    KW: "Kuwait",
    LB: "Lebanon",
    MX: "Mexico",
    PE: "Peru",
    PH: "Philippines",
    RO: "Romania",
    RU: "Russia",
    SI: "Slovenia",
    GB: "United Kingdom",
    UY: "Uruguay",
  };

  // animations
  const [styles, animate] = useSpring(() => ({
    from: { opacity: 0, scale: 0 },
  }));

  const fireAnimation = () => {
    animate({ to: { opacity: 1, scale: 1 } });
  };

  useEffect(() => {
    document.addEventListener("SceneIsBeingRevealed", fireAnimation);
    document.addEventListener("ToggleCustomerSupport", () => setOpen(!isOpen));
    return () => {
      document.removeEventListener("SceneIsBeingRevealed", fireAnimation);
    };
  }, []);

  const onSubmit = (data) => {
    if (window.location.href.includes("localhost")) return;

    data.description = `${data.description} --- *The customer was viewing the following playset at the time of reaching out:* ${
      EmbedController.isEmbedded ? EmbedController.parentUrl : window.location.href
    }`;
    data.oid = process.env.REACT_APP_SFDC_OID;

    SendMessageToSlack(
      `
      📢 Shopper is submitting customer support request:
      Name: ${data.first_name} ${data.last_name}
      Email: ${data.email}
      Phone: ${data.phone}
      Zip: ${data.zip}
      Description: ${data.description}
      `
    );

    if (EmbedController.isEmbedded) {
      console.log("isEmbedded sales inquiry");
      EmbedController.sendSalesInquiry(data);
    } else {
      webToLead(data);
    }
  };

  // sfdc doesn't have an api so we need to spoof cors
  const webToLead = (fields) => {
    var customHiddenIframeName = "SFDC_API";
    if (!document.getElementById(customHiddenIframeName)) {
      var theiFrame = document.createElement("iframe");
      theiFrame.id = customHiddenIframeName;
      theiFrame.name = customHiddenIframeName;
      theiFrame.src = "about:blank";
      theiFrame.style.display = "none";
      document.body.appendChild(theiFrame);
    }
    fields["retURL"] = "http://127.0.0.1"; //dummy URL
    var form = document.createElement("form");
    form.method = "POST";
    form.action = "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
    form.setAttribute("target", customHiddenIframeName);
    for (var fieldName in fields) {
      var theInput = document.createElement("input");
      theInput.name = fieldName;
      theInput.value = fields[fieldName];
      theInput.setAttribute("type", "hidden");
      form.appendChild(theInput);
    }
    document.body.appendChild(form);
    form.submit();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "Customer-Support-Submitted" });
  };

  const handleWidgetClick = () => {
    setOpen(!isOpen);
    if (!isOpen) {
      pushGTMAnalyticsEvent("Customer-Support-Click");
    }
  };

  const handleCopyLinkClick = () => {
    {
      navigator.clipboard.writeText(EmbedController.isEmbedded ? EmbedController.parentUrl : window.location.href);
    }
    setCopyUrlClicked(true);
    pushGTMAnalyticsEvent("Copy-Playset-Inside-Customer-Support-Click");
  };

  // change copy of button back to true
  useEffect(() => {
    if (isCopyUrlClicked) {
      setTimeout(() => {
        setCopyUrlClicked(false);
      }, 7000);
    }
  }, [isCopyUrlClicked]);

  // content to show in overlay on btn click
  const tooltipContent = {
    onHover: "Reach out",
    placement: "bottom",
  };

  return (
    <>
      <Boop rotation={20} timing={200}>
        <animated.div style={styles} className="customerSupport m-2" onClick={() => handleWidgetClick()}>
          <CircleButton
            circleColor={"rgba(5, 79, 174, 0.8)"}
            icon={isOpen ? "/images/iconCancel.svg" : "/images/iconMessage.svg"}
            tooltipContent={tooltipContent}
          />
        </animated.div>
      </Boop>

      {isOpen && (
        <Row className="g-0 supportCard">
          <Col xs="12" className="topSection">
            <div className="pt-4 p-3">
              {isSubmitSuccessful ? (
                <h4 className="pb-3">Congrats on customizing your Rainbow Play System 🎉</h4>
              ) : (
                <>
                  <h4 className="pb-3">Hi there, we're here to help!</h4>
                  <p>Reach out to speak to one of our sales representatives.</p>
                </>
              )}
            </div>
          </Col>
          <Col xs="12" className="bottomSection">
            <div style={{ padding: "0px 15px" }}>
              {isSubmitSuccessful ? (
                <div className="submitNote mt-5">
                  <img src="/images/support.svg" />
                  <h6 className="mt-5">We'll be in contact with you shortly with the email on file.</h6>
                  <div className="copyURL mb-3">
                    <h6 className="mt-4 text-left">Share or save link</h6>
                    <div className="d-flex linkContainer">
                      {/* adds ... after 30 characters */}
                      <p className="link ms-2 my-auto">{window.location.href.substr(0, isMobile ? 25 : 30) + "\u2026"}</p>
                      <button className="ms-auto my-auto" onClick={() => handleCopyLinkClick()}>
                        {isCopyUrlClicked ? "Copied!" : "Copy link"}
                      </button>
                    </div>
                  </div>
                  <a href="#" onClick={() => reset()}>
                    Have another question?
                  </a>
                </div>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group">
                    <label htmlFor="first_name">First Name:</label> <span className="asterisk">*</span>
                    <input
                      type="text"
                      className="mb-3 form-control"
                      name="first_name"
                      id="first_name"
                      {...register("first_name", {
                        required: true,
                        maxLength: 40,
                      })}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="last_name">Last Name:</label> <span className="asterisk">*</span>
                    <input
                      type="text"
                      className="mb-3 form-control"
                      name="last_name"
                      id="last_name"
                      {...register("last_name", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <span className="asterisk">*</span>
                    <input
                      type="email"
                      className="mb-3 form-control"
                      name="email"
                      id="email"
                      placeholder="This is how we'll reach out."
                      {...register("email", {
                        required: true,
                        maxLength: 80,
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">Phone Number:</label>
                    <input
                      type="number"
                      className="mb-3 form-control"
                      id="phone"
                      placeholder="Sometimes it's nice to chat"
                      aria-describedby="phoneHelp"
                      name="phone"
                      {...register("phone", { required: false, maxLength: 40 })}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="zip">Zip Code / Postal Code</label>
                    <span className="asterisk">*</span>
                    <input
                      type="text"
                      className="mb-3 form-control"
                      id="zip"
                      placeholder="We'll find the closest showroom"
                      aria-describedby="zipCodeHelp"
                      name="zip"
                      {...register("zip", {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="country_code">Country:</label>
                    <span className="asterisk">*</span>
                    <select
                      className="mb-3 form-control"
                      id="country_code"
                      name="country_code"
                      {...register("country_code", {
                        required: true,
                      })}
                      defaultValue="United States"
                    >
                      {Object.entries(countries).map(([code, label]) => (
                        <option key={code} value={code}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Your Message:</label> <span className="asterisk">*</span>
                    <p className="helperText">We'll automatically attach the playset your viewing to your message!</p>
                    <textarea
                      placeholder="How can we help you?"
                      id="description"
                      className="mb-3 form-control"
                      cols="50"
                      rows="5"
                      name="description"
                      {...register("description", { required: true })}
                    ></textarea>
                  </div>

                  <button type="submit" className="btn btn-primary btn-sm submitButton">
                    Submit
                  </button>
                </form>
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
