import { useEffect, useRef } from "react";
import { CircleButton } from "../CircleButton/CircleButton";
import { Boop } from "../Animations/Boop";
import { useSpring, animated } from "react-spring";

export const WidgetHelp = ({ buildSectionStepperActiveIndex, isDetailsActive }) => {
  const drawer = useRef();
  // animations
  const [styles, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));

  const fireAnimation = () => {
    animate({ to: { opacity: 1, scale: 1 } });
  };

  // content to show in overlay on btn click
  const tooltipContent = {
    onHover: isDetailsActive ? "For help, click back button and try again!" : "Help",
    placement: "bottom",
  };

  useEffect(() => {
    document.addEventListener("SceneIsBeingRevealed", fireAnimation);
    return () => {
      document.removeEventListener("SceneIsBeingRevealed", fireAnimation);
    };
  }, []);

  const handleWidgetClick = () => {
    if (isDetailsActive) return;

    if (buildSectionStepperActiveIndex <= 1) {
      drawer.current = document.getElementsByClassName("Drawer")[0];
      drawer.current.scrollTop = 0;
    }
    document.dispatchEvent(new CustomEvent("HelpWidgetRunProductTour", { detail: true }));
  };

  return (
    <Boop rotation={20} timing={200}>
      <animated.div style={styles} onClick={() => handleWidgetClick()} className="m-2">
        <CircleButton circleColor={"rgba(5, 79, 174, 0.8)"} tooltipContent={tooltipContent} icon={"/images/iconHelp.svg"} />
      </animated.div>
    </Boop>
  );
};
