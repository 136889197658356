import { useCallback, useRef, useEffect } from "react";
import { Boop } from "../Animations/Boop";
import { useAtom } from "jotai";
import { buildSectionStepperAtom, update_buildSectionStepper_index } from "../../data/atoms/BuildSectionStepperData.js";

export const Navigation = ({ buildSectionStepperStateObj }) => {
  const stickyNavRef = useRef();
  const [buildSectionStepper] = useAtom(buildSectionStepperAtom);
  const [a, setActiveIndex] = useAtom(update_buildSectionStepper_index);

  const handlePrevious = () => {
    console.log(buildSectionStepper);
    setActiveIndex(buildSectionStepper.activeIndex - 1);
    if (buildSectionStepper.activeIndex !== null && buildSectionStepper.activeIndex !== 0) {
    }
  };

  const handleNext = useCallback(() => {
    if (buildSectionStepperStateObj.state.activeIndex < 2) {
      buildSectionStepperStateObj.setState(buildSectionStepperStateObj.state.activeIndex + 1);
    }
  }, [buildSectionStepperStateObj.state.activeIndex]);

  useEffect(() => {
    // apply className isSticking to navigation when it intersects with the top of the page
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(([e]) => e.target.classList.toggle("isSticking", e.intersectionRatio < 1), { threshold: [1] });
      observer.observe(stickyNavRef.current);
    }
  }, []);

  return (
    <div className="sticky pt-1 pb-1 buildSectionNavigation mt-1 mb-2 d-flex align-items-center justify-content-center" ref={stickyNavRef}>
      <Boop boopType="scale" scale="1.1" timing={200}>
        <img
          alt="previous button"
          className={`prevBtn mx-3 ${buildSectionStepper.activeIndex === 0 && `disable`}`}
          onClick={handlePrevious}
          src="/images/prev-btn-flag.png"
        />
      </Boop>

      <h4 className="text-center pt-1 my-auto">{buildSectionStepper.steps[buildSectionStepper.activeIndex]}</h4>

      <Boop boopType="scale" scale="1.1" timing={200}>
        <img alt="next button" className={`mx-3 ${buildSectionStepper.activeIndex === 2 && `disable`}`} onClick={handleNext} src="/images/next-btn-flag.png" />
      </Boop>
    </div>
  );
};
