import React from "react";
import "./styles.scss";

export const BuilderScreen = ({ children }) => {
  return (
    <div className="BuilderScreen" style={{ height: `100%` }}>
      {children}
    </div>
  );
};
