import React, { useState, useEffect, useRef } from "react";
import { SpinControls } from "./SpinControls";
import { useThree } from "@react-three/fiber";
import { BaseSetsCameraRotation } from "./Animations/BaseSetsCameraRotation";
import { AutoRotatePlatform } from "./Animations/AutoRotatePlatform";
import { IntroCameraAnimation } from "./Animations/IntroCameraAnimation";
import { useAssetLoader } from "./AssetSystem3d";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const BaseSetsSceneController = ({ baseData, buildSectionStepperActiveIndex }) => {
  const isSceneActive = buildSectionStepperActiveIndex === 0;
  const modelUri = baseData.array[baseData.activeIndex].playSystemModel;
  const animationDetails = baseData.array[baseData.activeIndex].animationDetails;
  const [isIntroAnimationComplete, setIsIntroAnimationComplete] = useState(false);
  const playIntroAnimationRef = useRef();

  // CAMERA
  const camera = useThree(({ camera }) => camera);
  const setCameraTransform = () => {
    if (isIntroAnimationComplete) {
      camera.rotation.set(0, 0, 0);
      camera.position.set(0, 1.6, 0);
    }
  };

  // LOADING SCREEN
  const tellLoadingScreenImReady = () => {
    if (!isSceneActive) return;
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("ItemAssetsLoaded"));
    }, 250);
  };

  // SCENE ENTRANCE AND EXIT
  useEffect(() => {
    setTimeout(() => handleSceneChange(), 100);
  }, [buildSectionStepperActiveIndex]);

  async function handleSceneChange() {
    // entrance
    if (isSceneActive) {
      setCameraTransform();
      await loadBaseSetsModel();
      tellLoadingScreenImReady();
    }
    // exit
    else {
      setIsIntroAnimationComplete(true);
    }
  }

  // called from loading screen when it disappears
  useEffect(() => {
    document.addEventListener("Scene1IsBeingRevealed", handleSceneBeingRevealed);
    return () => {
      document.removeEventListener("Scene1IsBeingRevealed", handleSceneBeingRevealed);
    };
  }, []);
  function handleSceneBeingRevealed() {
    // play intro animation
    if (!isIntroAnimationComplete && playIntroAnimationRef.current) {
      setTimeout(() => {
        playIntroAnimationRef.current();
      }, 250);
      pushGTMAnalyticsEvent("Step1-Is-Being-Revealed");
    }
  }

  // GLTF model
  const getAsset = useAssetLoader();
  const [gltfScene, setGltfScene] = useState();
  async function loadBaseSetsModel() {
    const gltf = await getAsset(modelUri);
    // set transparent = true on platforms
    gltf.scene.getObjectByName("platform-castle").material.transparent = true;
    gltf.scene.getObjectByName("platform-clubhouse").material.transparent = true;
    setGltfScene(gltf.scene);
  }

  return (
    <>
      {gltfScene && <primitive visible={isSceneActive} object={gltfScene} />}

      {/* camera animations */}
      <IntroCameraAnimation
        isSceneActive={isSceneActive}
        gltfUri={"/rainbow-play-general-models/step1/baseSets-animated-camera.glb"}
        isIntroAnimationComplete={isIntroAnimationComplete}
        setIsIntroAnimationComplete={setIsIntroAnimationComplete}
        playIntroAnimationRef={playIntroAnimationRef}
      />

      {isSceneActive && isIntroAnimationComplete && animationDetails && (
        <>
          <BaseSetsCameraRotation cameraRotationAxis={animationDetails.cameraRotationAxis} />
        </>
      )}

      {/* ^^^ camera animations ^^^ */}

      {/* playset animations */}
      {isSceneActive && isIntroAnimationComplete && gltfScene && (
        <>
          <AutoRotatePlatform activeGltf={gltfScene} animationDetails={animationDetails} />
          <SpinControls activePlaySystemType={animationDetails.playSetType} activeGltf={gltfScene} />
        </>
      )}
      {/* ^^^ playset animations ^^^ */}
    </>
  );
};
