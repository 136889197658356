import * as THREE from "three";
import React, { useCallback, useRef, useState, useEffect, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { observer } from "mobx-react";
import { useAssetLoader } from "../../r3f/AssetSystem3d";
import { pushGTMAnalyticsEvent } from "../../../../modules/pushGTMAnalyticsEvent";
import { itemsAtom } from "../../../data/atoms/ItemsData";
import { useAtom } from "jotai";

const HotSpot = observer(({ mobX, model, ...rest }) => {
  const { hotspotPosOffset, nodeId, items, mods } = rest;
  const getAsset = useAssetLoader();
  const [itemsObj] = useAtom(itemsAtom);
  const ref = useRef();

  // some components only have 1 item option, which needs to be handled differently (no hotspot)
  const isSingleItem = items.length <= 1;

  const isHotSpotActive = mobX.show && mobX.component?.nodeId == nodeId;

  const componentNode = useMemo(() => model.getObjectByName(nodeId), [nodeId, model]);
  const currentItem = itemsObj?.[nodeId];

  useEffect(() => {
    updateItem();
  }, [itemsObj, currentItem, componentNode, items]);

  async function updateItem() {
    let activeItem = items[rest.defaultIndex].id;
    if (currentItem && !isSingleItem) {
      // if item is noted in URL and this is not a singleItem component (singleItems should always use default)
      activeItem = currentItem;
    }

    if (!componentNode) {
      return;
    }

    let item = items.find((item) => item.id == activeItem);

    if (!item) {
      // handle case where itemId in URL is incorrect
      activeItem = items[rest.defaultIndex].id;
      item = items.find((item) => item.id == activeItem);
    }

    if (item) {
      const gltf = await getAsset(item.model);

      let currentChild = componentNode.children && componentNode.children[0];
      if (currentChild) {
        componentNode.remove(currentChild);
      }

      // need to clone so identical items can be used across playsets
      let newItem = gltf.scene.clone();

      // activate realtime shadows on new item
      newItem.traverse((node) => {
        if (node.isMesh) node.castShadow = true;
      });

      // apply mods to newItem (just rotation as of 1/21/22)
      if (mods) {
        Object.entries(mods).forEach(([itemId, modObj]) => {
          if (item.id === itemId) {
            Object.entries(modObj).forEach(([property, newValue]) => {
              newItem[property].fromArray(newValue);
            });
          }
        });
      }

      componentNode.add(newItem);

      setIsPosUpdateNeeded(true);
    }
  }

  // Set position of hotspot to the component's (componentNode) position + hotspotPosOffset
  const [isPosUpdateNeeded, setIsPosUpdateNeeded] = useState(false);
  useFrame(() => {
    if (isPosUpdateNeeded) {
      setIsPosUpdateNeeded(false);
      setTimeout(function () {
        setHotspotPosition();
      }, 200); // delay so world matrix has time to update
    }
  });

  const [isPositionSet, setIsPositionSet] = useState(false);
  const hotspotPosOffsetVec3 = useRef(new THREE.Vector3());
  function setHotspotPosition() {
    if (!ref.current || isSingleItem) {
      return;
    }

    hotspotPosOffsetVec3.current.fromArray(hotspotPosOffset);
    // componentNode.updateMatrixWorld(true, false);
    componentNode.localToWorld(hotspotPosOffsetVec3.current);
    ref.current.position.copy(hotspotPosOffsetVec3.current);

    setIsPositionSet(true);
  }

  // visual stuff

  const [hovered, setHovered] = useState(false);

  const handleHoverOver = useCallback(() => {
    setHovered(true);
  }, []);

  const handleHoverOut = useCallback(() => {
    setHovered(false);
  }, []);

  const handleClick = useCallback(() => {
    if (isHotSpotActive) {
      mobX.hideManager();
    } else {
      mobX.showManager(rest);
      pushGTMAnalyticsEvent("Customize-Btn-Hotspot-Click");
    }
  }, [isHotSpotActive]);

  const time = useRef(0);
  const newScale = useRef(1);
  useFrame((state, dt) => {
    if (isSingleItem) return;
    // rotation
    ref.current.rotation.x += 0.01;

    // scale active hotspot
    if (isHotSpotActive) {
      time.current += dt;
      newScale.current = Math.abs(Math.sin(time.current)) + 0.5;
      ref.current.scale.set(newScale.current, newScale.current, newScale.current);
    }
  });

  useEffect(() => {
    ref.current.scale.set(1, 1, 1);
  }, [isHotSpotActive]);

  return (
    <>
      <mesh
        // position set above in code
        ref={ref}
        visible={isPositionSet || !isSingleItem}
        onClick={handleClick}
        onPointerOver={handleHoverOver}
        onPointerOut={handleHoverOut}
      >
        <dodecahedronGeometry args={[0.15, 0]} />
        <meshStandardMaterial args={[{ transparent: true, opacity: 0.75, color: "white" }]} wireframe={hovered || isHotSpotActive ? true : false} />
      </mesh>
    </>
  );
});

export default HotSpot;
