// gets playset components from json file
import axios from 'axios';

export const getPlaySetComponents = async (productData) => {
  if (!window.componentsCache) window.componentsCache = {};
  let components = [];

  if (productData.components && Array.isArray(productData.components)) {
    productData.components.forEach((component) => {
      const fetchPromise = new Promise(async (resolve) => {
        if (window.componentsCache[component.uri]) {
          resolve({ ...window.componentsCache[component.uri], ...component });
          return;
        }
        const response = await axios(component.uri);
        if (response) {
          const json = response.data
          window.componentsCache[component.uri] = json;
          resolve({ ...json, ...component });
        } else {
          console.error(`Error getting components for ${component.uri}.`);
          console.error(response);
        }
      });
      components.push(fetchPromise);
    });
  }

  components = await Promise.all(components);
  return components;
};
