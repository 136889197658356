import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "react-bootstrap/Container";
import "./styles.scss";

export const BuildSection = ({ children, sizeInfo }) => {
  const matches = useMediaQuery(`(min-width:769px) and (orientation: landscape)`, { noSsr: true }); // returns bool

  // sets the drawer position depending on viewport size. if width changes you must changes in BuilderScreen styles.scss
  const handleDrawerPosition = () => {
    if (matches) return { top: 0, left: 0, height: "100vh", width: "325px" };
    else return { bottom: 0, height: "55%", width: "100%" };
  };

  return (
    <Container id="drawer" fluid className="bg-white Drawer p-0" style={handleDrawerPosition()}>
      {children}
    </Container>
  );
};

BuildSection.propTypes = {
  children: PropTypes.node.isRequired,
};
