import React, { useEffect, useState, useRef } from "react";
import { useThree } from "@react-three/fiber";
import { useAssetLoader } from "./AssetSystem3d";

export const SceneryModelController = ({ baseData, sizeData, buildSectionStepperActiveIndex }) => {
  // active data obj used to know which sign should be active
  const [activeDataObj, setActiveDataObj] = useState();
  useEffect(() => {
    setActiveDataObj(sizeData.array[baseData.activeIndex][sizeData.activeIndex]);
  }, [baseData.activeIndex, sizeData.activeIndex]);

  // used to know if the scenery has bee reached (step 2 or step 3 has been entered)
  const [hasSceneryBeenReached, setHasSceneryBeenReached] = useState(false);
  useEffect(() => {
    if (buildSectionStepperActiveIndex > 0) setHasSceneryBeenReached(true);
  }, [buildSectionStepperActiveIndex]);

  // loadScenery
  useEffect(() => {
    if (hasSceneryBeenReached) loadScenery();
  }, [hasSceneryBeenReached]);

  async function loadScenery() {
    await loadSceneryModel();
  }
  const getAsset = useAssetLoader();
  const [gltfScene, setGltfScene] = useState();
  async function loadSceneryModel() {
    const gltf = await getAsset("/rainbow-play-general-models/scenery/scenery-basis-draco.glb");
    setGltfScene(gltf.scene);
    document.dispatchEvent(new CustomEvent("SceneryLoaded"));
  }

  // handle visiblity of different scenery depending upon which scene we're on
  useEffect(() => {
    if (!gltfScene) return;

    // sizes step. Show scenery but hide the step3 groups
    if (buildSectionStepperActiveIndex === 1) {
      gltfScene.traverse((node) => {
        if (node.name.includes("step3-group")) node.visible = false;
      });
    }

    // playset step. Reveal step3 scenery
    if (buildSectionStepperActiveIndex === 2) {
      gltfScene.traverse((node) => {
        if (node.name.includes("step3-group")) node.visible = true;
      });
    }
  }, [buildSectionStepperActiveIndex, gltfScene]);

  // baseSets aka don't show this scenery
  if (!hasSceneryBeenReached) return null;

  return (
    <>
      {gltfScene && <primitive visible={buildSectionStepperActiveIndex > 0} object={gltfScene} />}

      {/* change logo image on wooden sign */}
      {gltfScene && <PlaysetLogoSignController activeSizesDataObj={activeDataObj} />}
    </>
  );
};

function PlaysetLogoSignController({ activeSizesDataObj }) {
  const scene = useThree(({ scene }) => scene);

  // create ref to logo-sign-group
  const logoContainer = useRef();
  useEffect(() => {
    logoContainer.current = scene.getObjectByName(`logo-sign-group`);
    // make all logo's invisible to start
    logoContainer.current.visible = false;
  }, []);

  // set all logos to invisible except...
  // set newly active playset's logo to visible via activeSizesDataObj._id
  useEffect(() => {
    if (!logoContainer.current) return;

    // get active sign's name. Circus, sunshine, and fiesta only have 1 logo so those name's won't include clubhouse or castle
    var activeSignName;
    if (activeSizesDataObj._id.includes("circus") || activeSizesDataObj._id.includes("sunshine") || activeSizesDataObj._id.includes("fiesta"))
      activeSignName = `${activeSizesDataObj.animationDetails.playSetType}-logo-sign`;
    else activeSignName = `${activeSizesDataObj._id}-logo-sign`;

    // set visibility
    logoContainer.current.traverse((node) => {
      if (node.name === "logo-sign-group")
        // container
        node.visible = true;
      else if (node.name === activeSignName)
        // active sign
        node.visible = true;
      else node.visible = false;
    });
  }, [activeSizesDataObj, logoContainer.current]);

  return null;
}
