import * as THREE from "three";
import React, { Suspense, useMemo } from "react";
import { ResizeObserver } from "@juggle/resize-observer";
import { Canvas, useThree } from "@react-three/fiber";
import { EnvironmentController } from "./EnvironmentController";
import { BaseSetsSceneController } from "./BaseSetsSceneController";
import { SizesSceneController } from "./SizesSceneController";
import { PlaysetSceneController } from "./PlaysetSceneController";
import { SceneryModelController } from "./SceneryModelController";
import AssetSystem3d from "./AssetSystem3d.js";

export const Scene = ({ baseData, sizeData, productData, buildSectionStepperActiveIndex }) => {
  return (
    <>
      <Canvas
        id="builder-scene-canvas-container"
        className="shared-scene-sizing builder-scene-canvas-container"
        camera={{ position: [0, 1.6, 0], fov: 45, near: 0.1, far: 60 }}
        dpr={window.devicePixelRatio}
        shadows="true"
        resize={{ polyfill: ResizeObserver }}
      >
        <SetupRenderer />

        <Suspense fallback={null}>
          {/* envMap and anything that will make up the "environment" throughout the WHOLE experience */}
          <EnvironmentController buildSectionStepperActiveIndex={buildSectionStepperActiveIndex} />
        </Suspense>

        <AssetSystem3d>
          {/* Step 1 controller */}
          <BaseSetsSceneController baseData={baseData} buildSectionStepperActiveIndex={buildSectionStepperActiveIndex} />

          {/* Scenery for steps 2 and 3 */}
          <SceneryModelController baseData={baseData} sizeData={sizeData} buildSectionStepperActiveIndex={buildSectionStepperActiveIndex} />

          {/* Step 2 controller */}
          <SizesSceneController baseData={baseData} sizeData={sizeData} buildSectionStepperActiveIndex={buildSectionStepperActiveIndex} />

          {/* Step 3 controller */}
          <PlaysetSceneController
            baseData={baseData}
            sizeData={sizeData}
            productData={productData}
            buildSectionStepperActiveIndex={buildSectionStepperActiveIndex}
          />
        </AssetSystem3d>
      </Canvas>
    </>
  );
};

function SetupRenderer() {
  const gl = useThree(({ gl }) => gl);
  useMemo(() => {
    gl.physicallyCorrectLights = true;
    gl.toneMapping = THREE.NoToneMapping;
  }, [gl]);
  return null;
}
