import { useState, useEffect } from "react";
import { useRouter } from "wouter";
import { urlPatterns } from "./UrlDataController";

const currentLocation = () => {
  return window.location.hash;
};

const navigate = (targetHash) => {
  window.location.hash = targetHash;
};

export const useHashLocation = () => {
  const [loc, setLoc] = useState(currentLocation());

  function handleHashChange() {
    setLoc(currentLocation());
  }

  useEffect(() => {
    // subscribe to hash changes
    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  return [loc, navigate];
};

export const useParamsFromHash = () => {
  const [path] = useHashLocation();
  const router = useRouter();
  let paramsFromHash = {};
  urlPatterns.forEach((pattern) => {
    const [match, params] = router.matcher(pattern, path);
    if (match) paramsFromHash = params;
  });
  return paramsFromHash;
};
