import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Specs = ({ stepIndex, detailData, isActive }) => {
  if (!isActive) return null;

  return (
    <>
      <Row className="g-0">
        <Col className="my-2">
          <h3 className="text-center">Specs</h3>
          <hr
            className="mx-auto"
            style={{
              transform: "rotate(-2deg)",
              filter: "drop-shadow(4px 4px 2px gray)",
              background: "linear-gradient(90deg, rgba(255,195,9,1) 0%, rgba(5,79,174,1) 100%)",
              width: 55,
              height: 3,
              opacity: 100,
              borderRadius: 10,
            }}
          />
          {stepIndex === 2 && (
            <p className="text-center" style={{ color: "#777", textDecoration: "underline" }}>
              Weight will vary if playset is customized
            </p>
          )}
        </Col>
      </Row>
      <Row className="g-0 px-2">
        {Object.keys(detailData.array.specs).map((key, index) => (
          <Col className="specs" xs={6} key={index}>
            <div className="d-flex align-items-center justify-content-center m-2 mb-3">
              <img style={{ width: "36px" }} alt="feature icon" className="mx-1" src={detailData.array.specs[key].icon} />
              <div className="text-center">
                <p className="title text-lowercase m-0">{detailData.array.specs[key].name}</p>
                <p className="size m-0">{detailData.array.specs[key].size}</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};
