import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";
import Tooltip from "react-bootstrap/Tooltip";
import { useState, useEffect } from "react";
import "./styles.scss";

export const CircleButton = ({ icon, circleColor, circleSize = "40px", tooltipContent }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isTooltipClicked, setIsTooltipClicked] = useState(false);

  // used to disable onHover tooltips on touch devices
  const isTouchDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  };

  const tooltip = (props) => (
    <Tooltip id="button-tooltip" style={{ zIndex: 30, position: "absolute" }} {...props}>
      {isTooltipClicked ? tooltipContent.onClick : tooltipContent.onHover}
    </Tooltip>
  );

  // change back tooltip content after timeout
  useEffect(() => {
    if (isTooltipClicked) {
      setTimeout(() => {
        setIsTooltipClicked(false);
      }, 7000);
    }
  }, [isTooltipClicked]);

  // gets called every time user mouse enters or exits
  const overlayHandler = () => {
    if (!tooltipContent || isTouchDevice()) return;

    setShowTooltip(!showTooltip);
  };

  // updates tooltip content when btn clicked
  const onClickHandler = () => {
    if (!tooltipContent?.onClick) return;

    setIsTooltipClicked(true);
  };

  return (
    <OverlayTrigger placement={tooltipContent?.placement} show={showTooltip} overlay={tooltip} onToggle={() => overlayHandler()}>
      <div
        onClick={() => onClickHandler()}
        className="circle d-flex justify-content-center align-items-center"
        style={{ backgroundColor: circleColor, height: circleSize, width: circleSize }}
      >
        <img src={icon} />
      </div>
    </OverlayTrigger>
  );
};

CircleButton.propTypes = {
  // Icon that will be in center of circle
  icon: PropTypes.string.isRequired,
  // The color of the cirlce button.
  circleColor: PropTypes.string.isRequired,
  // Content data for ToolTip to render corrently
  tooltipContent: PropTypes.shape({
    onHover: PropTypes.string.isRequired,
    onClick: PropTypes.string,
    placement: PropTypes.string,
  }),
  // Dimensions of the circle
  circleSize: PropTypes.string,
};
