import * as THREE from "three";
import { useEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useAssetLoader } from "../AssetSystem3d";

export function IntroCameraAnimation({ isSceneActive, gltfUri, playIntroAnimationRef, isIntroAnimationComplete, setIsIntroAnimationComplete }) {
  useEffect(() => {
    if (isSceneActive) loadAnimationGltf();
  }, []);

  useEffect(() => {
    if (!isSceneActive) setIsIntroAnimationComplete(true);
  }, [isSceneActive]);

  // GLTF model with animations
  const getAsset = useAssetLoader();
  async function loadAnimationGltf() {
    const gltf = await getAsset(gltfUri);
    syncCameraWithAnimationStartingTransform(gltf);
    setupAnimation(gltf);
  }

  const camera = useThree(({ camera }) => camera);

  function syncCameraWithAnimationStartingTransform(gltf) {
    let posVals = gltf.animations[0].tracks[0].values;
    let quatVals = gltf.animations[0].tracks[1].values;

    let startPos = [posVals[0], posVals[1], posVals[2]];
    let startQaut = [quatVals[0], quatVals[1], quatVals[2], quatVals[3]];

    camera.position.fromArray(startPos);
    camera.quaternion.fromArray(startQaut);
  }

  // animations
  const animationMixerRef = useRef();
  const clipActionRef = useRef();

  function setupAnimation(gltf) {
    let animationMixer = new THREE.AnimationMixer(camera);
    animationMixerRef.current = animationMixer;
    clipActionRef.current = animationMixer.clipAction(gltf.animations[0]);
    clipActionRef.current.clampWhenFinished = true;
    clipActionRef.current.setLoop(THREE.LoopOnce);
    // give parent access to playAnimation()
    playIntroAnimationRef.current = playAnimation;
  }

  function playAnimation() {
    clipActionRef.current.paused = false;
    clipActionRef.current.setEffectiveTimeScale(2);
    clipActionRef.current.play();
  }

  useEffect(() => {
    if (animationMixerRef.current) animationMixerRef.current.addEventListener("finished", (e) => setIsIntroAnimationComplete(true));
  }, [animationMixerRef.current]);

  useFrame((state, delta) => {
    if (animationMixerRef.current && !isIntroAnimationComplete) {
      animationMixerRef.current.update(delta);
    }
  });

  return null;
}
