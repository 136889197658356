import React, { useEffect, useMemo, useState } from "react";
import HotSpot from "./components/HotSpot";
import XComponentsManger from "./XComponentsManager";
import { getPlaySetComponents } from "../../../modules/getPlaySetComponents";
import { sizeInfoAtom } from "../../data/atoms/SizeInfoData";
import { useAtom } from "jotai";

const ComponentsSelector = ({ productData, model }) => {
  const [components, setComponents] = useState([]);
  const [product, setProduct] = useState(null);
  const [sizeInfo] = useAtom(sizeInfoAtom);

  const productId = useMemo(() => {
    if (!productData) {
      return null;
    }

    return productData._id;
  }, [productData]);

  // loads the component / item json
  useEffect(async () => {
    setComponents([]);

    if (!productId) return;

    const components = await getPlaySetComponents(productData);

    setComponents(components);

    productData.components = components;
    setProduct({ ...productData });

    // tell the loading screen im ready (for step 3)
    // 1 second delay to let items begin loading
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("ItemAssetsLoaded"));
    }, 1000);
    // signal to product card
    document.dispatchEvent(new CustomEvent("ComponentsUpdated", { detail: components }));
  }, [productId, sizeInfo.isActive]);

  if (!product || sizeInfo.isActive || components.length == 0) {
    return null;
  }

  return components.map((component, i) => <HotSpot key={`hot-spot-${i}`} mobX={XComponentsManger} model={model} product={product} {...component} />);
};

export default ComponentsSelector;
