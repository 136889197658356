import React, { useState, useEffect, useContext } from "react";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { TextureLoader } from "three";
// import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";
// import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader.js';
import { KTX2Loader } from "./PatchedKTX2Loader.js";
import { BasisTextureLoader } from "./PatchedBasisTextureLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";

const AssetSystemContext = React.createContext();

export function useAssetLoader() {
  return useContext(AssetSystemContext);
}

const assetCache = {};

export default function AssetSystem3d({ children }) {
  const renderer = useThree((state) => state.gl);

  const [textureLoader, setTextureLoader] = useState(null);
  const [basisLoader, setBasisLoader] = useState(null);
  const [ktx2Loader, setKtx2Loader] = useState(null);
  const [gltfLoader, setGltfLoader] = useState(null);
  const [loadersReady, setLoadersReady] = useState(false);

  function updateAssetCache(assetURI, asset) {
    assetCache[assetURI] = asset;
  }

  // _________________________________________________
  // start of loader creation code
  // _________________________________________________

  useEffect(() => {
    if (!loadersReady) {
      createLoaders();
      setLoadersReady(true);
    }
  }, []);

  function createLoaders() {
    const textureLoader = createTextureLoader();
    let basisLoader = createBasisTextureLoader();
    const ktx2Loader = createKtx2Loader();
    const gltfLoader = createModelLoader(ktx2Loader);

    setTextureLoader(textureLoader);
    setBasisLoader(basisLoader);
    setKtx2Loader(ktx2Loader);
    setGltfLoader(gltfLoader);
  }

  function createTextureLoader() {
    return new TextureLoader();
  }

  function createBasisTextureLoader() {
    var basisLoader = new BasisTextureLoader();
    basisLoader.setTranscoderPath("/basis/");
    basisLoader.detectSupport(renderer);
    return basisLoader;
  }

  function createKtx2Loader() {
    var ktx2Loader = new KTX2Loader();
    ktx2Loader.setTranscoderPath("/basis/");
    ktx2Loader.detectSupport(renderer);
    return ktx2Loader;
  }

  function createModelLoader(ktx2TextureLoader) {
    var gltfLoader = new GLTFLoader();
    var dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco/");
    gltfLoader.setDRACOLoader(dracoLoader);
    gltfLoader.setKTX2Loader(ktx2TextureLoader);
    gltfLoader.setMeshoptDecoder(MeshoptDecoder);
    return gltfLoader;
  }
  // _________________________________________________
  // end of loader creation code
  // _________________________________________________

  // _________________________________________________
  // start of asset loading code
  // _________________________________________________

  async function getAsset(assetURI) {
    if (!assetURI) return;

    if (assetCache[assetURI]) return assetCache[assetURI];

    var asset;
    if (assetURI.includes("models")) asset = await loadModel(assetURI);
    else if (assetURI.includes("textures")) asset = await loadTexture(assetURI);

    return asset;
  }

  async function loadModel(assetURI) {
    return new Promise((resolve) => {
      gltfLoader.load(assetURI, (gltf) => {
        updateAssetCache(assetURI, gltf);
        resolve(gltf);
      });
    });
  }

  async function loadTexture(assetURI) {
    // console.log("LOADING TEXTURE", assetURI);

    return new Promise((resolve) => {
      getCorrectLoader(assetURI).load(assetURI, (texture) => {
        // console.log(`~~~ ${assetURI} loaded~~~`, texture)
        texture = prepareTexture(assetURI, texture);
        updateAssetCache(assetURI, texture);
        resolve(texture);
      });
    });
  }

  function getCorrectLoader(textureSrc) {
    var correctLoader;

    if (textureSrc.includes("ktx2")) {
      correctLoader = ktx2Loader;
    } else if (textureSrc.includes("basis")) {
      correctLoader = basisLoader;
    } else {
      correctLoader = textureLoader;
    }

    return correctLoader;
  }

  function prepareTexture(assetURI, texture) {
    if (assetURI.includes("normal") || assetURI.includes("metal") || assetURI.includes("roughness")) {
      // handle non-color data textures
      texture.encoding = THREE.LinearEncoding;
      if (assetURI.includes("normalMap")) {
        texture.minFilter = THREE.NearestFilter;
        texture.magFilter = THREE.NearestFilter;
      }
    } else {
      texture.encoding = THREE.sRGBEncoding;
    }

    texture.flipY = false;

    return texture;
  }

  // _________________________________________________
  // end of asset loading code
  // _________________________________________________

  if (!loadersReady) return null;
  else {
    return <AssetSystemContext.Provider value={getAsset}>{children}</AssetSystemContext.Provider>;
  }
}
