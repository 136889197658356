import React, { useEffect, useState, useRef } from "react";
import { useSpring, animated } from "react-spring";

import "./styles.scss";

export default function GrabSceneIndicator({ buildSectionStepperActiveIndex }) {
  const [isIconVisible, setIsIconVisible] = useState(false);
  const [isReset, setIsReset] = useState(false);
  // used for step 1 & 2 bc you can only rotate model
  const hasRotateBeenTouched = useRef(false);
  // used for step 3
  const hasOrbitBeenTouched = useRef(false);
  const buildSectionStepperActiveIndexRef = useRef(buildSectionStepperActiveIndex);

  // used to sync the indicator to intro animations
  const isFirstLoadScene1Ref = useRef(true);
  const isFirstLoadScene2Ref = useRef(true);
  const timeoutId = useRef();

  // orbit animation used in step 3
  const orbitAnimation = useSpring({
    loop: true,
    from: { transform: "rotateZ(0deg) translateX(50px) rotate(0deg)" },
    to: { transform: "rotateZ(360deg) translateX(50px) rotate(-360deg)" },
    config: {
      duration: 2000,
    },
  });

  // rotate animation used in step 1 & 2
  const rotateAnimation = useSpring({
    loop: { reverse: true },
    from: { x: -50 },
    to: { x: 50 },
    onRest: () => setIsReset(true), // animation stops when camera moves so have to reset when it happens
    reset: isReset,
    reverse: isReset, // start from the end
  });

  useEffect(() => {
    // setup listener to know when scene is revealed
    document.addEventListener("SceneIsBeingRevealed", revealIcon);

    // setup listeners to know when scene is clicked or touched
    let sceneCanvas = document.getElementById("builder-scene-canvas-container").children[0];
    if (sceneCanvas) sceneCanvas.addEventListener("mousedown", handleMouseDown);
    if (sceneCanvas) sceneCanvas.addEventListener("touchstart", handleMouseDown);

    return () => {
      document.removeEventListener("SceneIsBeingRevealed", revealIcon);
      sceneCanvas.removeEventListener("mousedown", handleMouseDown);
      sceneCanvas.removeEventListener("touchstart", handleMouseDown);
    };
  }, []);

  useEffect(() => {
    // prevents timeout from firing if user skips intro animation
    if (timeoutId.current) clearTimeout(timeoutId.current);

    buildSectionStepperActiveIndexRef.current = buildSectionStepperActiveIndex;
    setIsIconVisible(false);
  }, [buildSectionStepperActiveIndex]);

  function revealIcon() {
    if ((hasRotateBeenTouched.current && buildSectionStepperActiveIndexRef.current <= 1) || window.isProductTourActive) return;
    if (hasOrbitBeenTouched.current && buildSectionStepperActiveIndexRef.current === 2) return;

    const delayInMilliseconds = delayIndicatorFactory(buildSectionStepperActiveIndexRef.current, isFirstLoadScene1Ref.current, isFirstLoadScene2Ref.current);

    timeoutId.current = setTimeout(function () {
      setIsIconVisible(true);
    }, delayInMilliseconds);

    // set this after initial intro animations to display indicator at correct time
    if (buildSectionStepperActiveIndexRef.current === 0 && isFirstLoadScene1Ref.current) isFirstLoadScene1Ref.current = false;
    if (buildSectionStepperActiveIndexRef.current === 1 && isFirstLoadScene2Ref.current) isFirstLoadScene2Ref.current = false;
  }

  function handleMouseDown() {
    if (buildSectionStepperActiveIndexRef.current <= 1) {
      setIsIconVisible(false);
      hasRotateBeenTouched.current = true;
    } else {
      setIsIconVisible(false);
      hasOrbitBeenTouched.current = true;
    }
  }

  return (
    <>
      {isIconVisible && (
        <animated.img
          style={buildSectionStepperActiveIndex <= 1 ? rotateAnimation : orbitAnimation}
          alt="Grab Icon Indicator"
          src="/images/grabSceneIndicator.png"
          className="grabSceneIndicator"
        />
      )}
    </>
  );
}

const delayIndicatorFactory = (buildSectionStepperActiveIndex, isFirstLoadScene1Ref, isFirstLoadScene2Ref) => {
  let delayInMilliseconds;
  switch (buildSectionStepperActiveIndex) {
    case 0:
      let scene1AnimationWillPlay = true;

      // check if user has visited scene 2. if so, animation will not play
      if (!isFirstLoadScene2Ref) scene1AnimationWillPlay = false;

      delayInMilliseconds = isFirstLoadScene1Ref && scene1AnimationWillPlay ? 6000 : 2000;
      break;

    case 1:
      delayInMilliseconds = 2000;
      break;

    case 2:
      delayInMilliseconds = 2000;
      break;

    default:
      delayInMilliseconds = 6000;
  }

  return delayInMilliseconds;
};
