import { useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

export const LerpModelPosition = ({ lerpPosition, activeGltf }) => {
  const targetPos = useMemo(() => {
    return lerpPosition ? new THREE.Vector3(...lerpPosition) : null;
  }, [lerpPosition]);

  const playSystems = useMemo(() => {
    return activeGltf.getObjectByName("all-playsets-group");
  }, [activeGltf]);

  useFrame((state, deltaTime) => {
    if (activeGltf.children.length === 0 || !lerpPosition) return;
    if (deltaTime > 0.1) deltaTime = 0.1; // fixes bug when tab is unfocused and refocused
    if (Math.abs(playSystems.position.x - targetPos.x) <= 0.01) return; // stops lerping when we're close enough to targetPos
    playSystems.position.lerp(targetPos, deltaTime * 5);
  });

  return null;
};
