import React, { useState, useEffect, useRef } from "react";
import { useThree } from "@react-three/fiber";
import { OrbitControls, Plane } from "@react-three/drei";
import { SizeInfoControls } from "./SizeInfoControls";
import { useAssetLoader } from "./AssetSystem3d";
import ComponentsSelector from "../ComponentsSelector/ComponentsSelector";

export const PlaysetSceneController = ({ baseData, sizeData, productData, buildSectionStepperActiveIndex }) => {
  const isSceneActive = buildSectionStepperActiveIndex === 2;
  const isFirstLoad_ref = useRef(true);
  const scene = useThree(({ scene }) => scene);

  // ACTIVE PRODUCT DATA
  const activeProductData = productData.array[baseData.activeIndex][sizeData.activeIndex][productData.activeIndex];
  const activeProductDataRef = useRef(activeProductData);

  // CAMERA
  const camera = useThree(({ camera }) => camera);
  const setCameraTransform = () => {
    camera.rotation.set(0, 0, 0);
    camera.position.set(0, 1.6, 13);
  };
  const [orbitTarget, setOrbitTarget] = useState([0, 1.6, 0]);

  // LOADING SCREEN
  // NOTE: the ComponentSelector fires off the loading event for this scene since it loads all the playset items
  // const tellLoadingScreenImReady = () => {
  //   if (!isSceneActive) return;
  //   setTimeout(() => { document.dispatchEvent(new CustomEvent("ItemAssetsLoaded")) }, 250);
  // }

  //  SCENE ENTRANCE AND EXIT
  useEffect(() => {
    setTimeout(() => handleSceneChange(), 100);
  }, [buildSectionStepperActiveIndex]);

  async function handleSceneChange() {
    // entrance
    if (isSceneActive) {
      setCameraTransform();
      await loadPlaysetModel();
      await loadRealtimePlatform();
    }
    // exit
    else {
      removeActiveModelFromScene();
      setActiveGltfScene(null);
    }
  }

  /**
   *
   * TRANSITION BETWEEN PLAYSETS IN STEP 3
   *
   */

  useEffect(() => {
    if (isSceneActive && !isFirstLoad_ref.current) loadPlaysetModel();
  }, [activeProductData]);

  // GLTF model
  const getAsset = useAssetLoader();
  async function loadPlaysetModel() {
    isFirstLoad_ref.current = false;

    activeProductDataRef.current = activeProductData;
    const activeProductBeingLoaded = activeProductData.playSystemModel;

    const newGltf = await getAsset(activeProductBeingLoaded);

    // check and make sure user hasn't clicked on a different playset since loading started
    if (activeProductBeingLoaded != activeProductDataRef.current.playSystemModel) {
      console.log("----------------------------------------------");
      console.log("playset selected has changed while loading model");
      console.log("----------------------------------------------");
      // if so, just return because this function is running again with new playset model
      return;
    }

    removeActiveModelFromScene();
    if (newGltf?.scene) addModelToScene(newGltf.scene);
  }

  function addModelToScene(model) {
    scene.add(model);
    setActiveGltfScene(model);
  }

  // ACTIVE GLTF
  const [activeGltfScene, setActiveGltfScene] = useState();

  function removeActiveModelFromScene() {
    if (activeGltfScene) scene.remove(activeGltfScene);
  }

  /**
   *
   * REALTIME SHADOWS ON PLATFORM
   *
   */

  // activate castShadow on playset, every time the activeGltfScene (playset) changes
  useEffect(() => {
    if (!activeGltfScene) return;
    var playsetGroup;
    activeGltfScene.traverse((node) => {
      if (node.name.includes("playset-group")) playsetGroup = node;
    });
    if (!playsetGroup) return;
    playsetGroup.traverse((node) => {
      if (node.isMesh) node.castShadow = true;
    });
  }, [activeGltfScene]);

  // realtime platform (grassy platform with scenery on it and no baked shadows except for the scenery)
  const [realtimePlatform, setRealtimePlatform] = useState();
  async function loadRealtimePlatform() {
    const realtimePlatformGltf = await getAsset("/rainbow-play-general-models/platform/realtime-platform-basis-meshopt.glb");
    setRealtimePlatform(realtimePlatformGltf.scene);
    // set renderOrder to 1 for proper sorting
    realtimePlatformGltf.scene.traverse((node) => {
      if (node.isMesh && node.material.name === "platform-no-shadow") node.renderOrder = 1;
    });
  }

  return (
    <>
      {/* NOTE: playset models for this scene are added manually via three.js so no primitive is used */}

      {/* shadow plane */}
      {isSceneActive && realtimePlatform && (
        <>
          <primitive object={realtimePlatform} rotation={[0, -0.4, 0]} />
          <Plane args={[10.7, 10.7]} renderOrder={2} receiveShadow={true} rotation={[-Math.PI / 2, 0, (-Math.PI / 180) * 35]} position={[0, 0.17, 0]}>
            <shadowMaterial attach="material" opacity={0.3} transparent={true} />
          </Plane>
        </>
      )}

      {/* hide or reveal size info */}
      {isSceneActive && activeGltfScene && <SizeInfoControls activeGltf={activeGltfScene} />}

      <OrbitControls
        enabled={isSceneActive}
        autoRotate={false}
        minDistance={3}
        maxDistance={13}
        maxPolarAngle={1.57}
        enableKeys={false}
        enablePan={false}
        target={orbitTarget}
      />

      {isSceneActive && activeGltfScene && <ComponentsSelector model={activeGltfScene} productData={activeProductData} />}
    </>
  );
};
