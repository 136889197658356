import { useState } from "react";
import PropTypes from "prop-types";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useSpring, animated } from "react-spring";
import { useUrlParams, styleParam, sizeParam } from "../Routing/UrlDataController";
import "./styles.scss";

export default function CustomStepper({ buildSectionStepperStateObj }) {
  const [showMessage, setMessage] = useState(false);
  const colors = ["#DF1C20", "#FFC309", "#054FAE"]; // red yellow blue
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const paramObjs = useUrlParams();

  const stepHandler = (index, buildSectionStepperActiveIndex) => {
    // prohibits user from skipping to step 3
    if (index === 2 && buildSectionStepperActiveIndex === 0) {
      setMessage(true);

      animate({ to: { opacity: 1, scale: 1 } });
      animate({ to: { opacity: 0, scale: 0 }, delay: 3000 });

      setTimeout(() => {
        setMessage(false);
      }, 3300);

      return;
    }

    buildSectionStepperStateObj.setState(index);
  };

  const firstCharToUpperCase = (str) => str?.charAt(0).toUpperCase() + str?.slice(1) || "";

  const getStepLabel = (index) => {
    const size = paramObjs[sizeParam]?.id?.split("_")[0] === "kingKong" ? "King Kong" : paramObjs[sizeParam]?.id?.split("_")[0];
    const stepperLabels = [firstCharToUpperCase(paramObjs[styleParam]?.id), firstCharToUpperCase(size), "Step 3"];
    return buildSectionStepperStateObj.state.activeIndex === index ? `Step ${index + 1}` : stepperLabels[index];
  };

  return (
    <>
      <Stepper id="Stepper" className="Stepper p-md-3" alternativeLabel activeStep={buildSectionStepperStateObj.state.activeIndex}>
        {buildSectionStepperStateObj.state.steps.map((label, index) => (
          <Step key={index} style={{ color: colors[index] }} onClick={() => stepHandler(index, buildSectionStepperStateObj.state.activeIndex)}>
            <StepLabel className={`m-0 ${buildSectionStepperStateObj.state.activeIndex > index ? "completedStep" : ""}`}>
              {buildSectionStepperStateObj.state.activeIndex >= index && getStepLabel(index)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {showMessage && (
        <animated.p style={animationProps} className="info-message-text px-3 text-center">
          Select your size in step 2 first!
        </animated.p>
      )}
    </>
  );
}

CustomStepper.propTypes = {
  // Data that comes from either context or ProductBuilder.jsx
  buildSectionStepper: PropTypes.shape({
    steps: PropTypes.array.isRequired,
    activeIndex: PropTypes.number.isRequired,
  }),
};
