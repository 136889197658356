import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import playSystemsBaseData from "../playSystemsBase.json";
import { isNumber } from "lodash";
import { styleParam } from "../../components/Routing/UrlDataController";

export const baseAtom = atom({
  array: playSystemsBaseData,
  activeIndex: 0,
});

export const update_base_index = atom(null);

export default function BaseData({ urlParamsObj, updateUrl }) {
  const [base, setBase] = useAtom(baseAtom);
  const [activeIndex, setActiveIndex] = useAtom(update_base_index);

  // handle request from children to update the activeIndex (url)
  useEffect(() => {
    if (isNumber(activeIndex) && activeIndex !== base.activeIndex) {
      updateUrl(activeIndex);
      setActiveIndex(null);
    }
  }, [activeIndex]);

  useEffect(() => {
    // update state when url changes
    let newIndex = urlParamsObj[styleParam]?.index;
    if (isNumber(newIndex) && newIndex !== base.activeIndex) {
      setBase({ array: playSystemsBaseData, activeIndex: newIndex });
    }
  }, [urlParamsObj]);

  // useEffect(() => {
  //   console.log("&&& base UPDATE", base.activeIndex);
  // }, [base]);

  return null;
}
