/** We use Joyride libary for our product guide
 * Joyride doesn't support hooks
 */

import React from "react";
import Joyride, { STATUS, ACTIONS, EVENTS } from "react-joyride";
import { isMobile } from "react-device-detect";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";
import EmbedController from "../../../embed/EmbedController";

export default class ProductTour extends React.Component {
  constructor(props) {
    super(props);

    let customLocale = { next: "Next Tip", skip: "Skip Tour" };

    this.state = {
      stepIndex: 0,
      run: false,
      steps: [
        {
          placement: isMobile ? "center" : "right",
          disableBeacon: true,
          id: "productTourStep0",
          target: isMobile ? "body" : ".productTourHighlightNavigateSteps",
          locale: customLocale,
          content: (
            <div className="ProductTour">
              <img id="pt_rp_logo" className="mb-3" src="/images/logo.svg" alt="Rainbow Play Logo" />
              <h4 className="mb-2">3-Step-Design-Process</h4>
              <h6 className="mb-4">Click on the arrows or color dots to navigate steps!</h6>
              <img className="stepperImg mb-1" src="/images/stepper-img.png" alt="stepper" />
            </div>
          ),
        },
        {
          target: ".selectCard",
          id: "productTourStep1",
          placement: "auto",
          locale: customLocale,
          content: (
            <div className="ProductTour">
              <h4>Select a Style</h4>
              <img style={{ width: "80px" }} className="mx-auto mb-2" alt="header underline" src="/images/headerLine.svg" />
              <p>
                Choose between a <b>Castle</b> with an upper play area and a tire swing or a <b>Clubhouse</b> with upper and lower play areas!
              </p>
            </div>
          ),
        },
        {
          target: ".customerSupport",
          id: "productTourStep2",
          placement: "auto",
          locale: customLocale,
          content: (
            <div className="ProductTour">
              <h4>Customer Support</h4>
              <img style={{ width: "80px" }} className="mx-auto mb-2" alt="header underline" src="/images/headerLine.svg" />
              <p>Feel free to reach out with any questions! We're always happy to chat!</p>
            </div>
          ),
        },
        {
          target: ".selectCard",
          id: "productTourStep3",
          placement: "auto",
          locale: customLocale,
          content: (
            <div className="ProductTour">
              <h4>Select a Size</h4>
              <img style={{ width: "80px" }} className="mx-auto mb-2" alt="header underline" src="/images/headerLine.svg" />
              <p>We offer sizes perfect for any yard! Our collection ranges from a small Fiesta size all the way up to a large King Kong size!</p>
            </div>
          ),
        },
        {
          target: ".toggleSizeButton",
          id: "productTourStep4",
          locale: customLocale,
          content: (
            <div className="ProductTour">
              <h4>Size Details</h4>
              <img style={{ width: "80px" }} className="mx-auto mb-2" alt="header underline" src="/images/headerLine.svg" />
              <p>Use this tool to see the playset's size info!</p>
            </div>
          ),
        },
        {
          placement: "center",
          id: "productTourStep5",
          target: "body",
          locale: customLocale,
          content: (
            <div className="ProductTour">
              <h4>Customize!</h4>
              <img style={{ width: "80px" }} className="mx-auto mb-2" alt="header underline" src="/images/headerLine.svg" />
              <p>Personalize your playset by swapping out different components like the roof, slides, and swings!</p>
              <p>Click the floating hotspots in the scene or the "customize" button on the playset card to get started!</p>
            </div>
          ),
        },
        {
          target: ".copyLinkButton",
          id: "productTourStep6",
          locale: { last: "Finish" },
          content: (
            <div className="ProductTour">
              <h4>Save or Share</h4>
              <img style={{ width: "80px" }} className="mx-auto mb-2" alt="header underline" src="/images/headerLine.svg" />
              <p>You can use this button when you want to save or share a playset!</p>
            </div>
          ),
        },
      ],
    };

    this.isSkipped = false;
    this.isReturningUser = this.getLocalStorage("rainbowPlayProductTour");
    this.productTourTimer = null;
    window.isProductTourActive = false;
  }

  componentDidMount() {
    document.addEventListener("SceneIsBeingRevealed", this.runProductTour);
    document.addEventListener("HelpWidgetRunProductTour", this.runProductTour);
  }

  componentWillUnmount() {
    document.removeEventListener("SceneIsBeingRevealed", this.runProductTour);
    document.removeEventListener("HelpWidgetRunProductTour", this.runProductTour);
  }

  componentDidUpdate(prevProps) {
    if (this.props.buildSectionStepperActiveIndex !== prevProps.buildSectionStepperActiveIndex) {
      clearTimeout(this.productTourTimer);
    }
  }

  // ttl is in days
  setLocalStorage = (keyName, keyValue, ttl) => {
    const data = {
      value: keyValue,
      ttl: Date.now() + ttl * 1000 * 60 * 60 * 24, // store the time to live
    };

    if (EmbedController.isEmbedded) EmbedController.sendLocalStorage("set", { keyName: keyName, value: JSON.stringify(data) });
    else localStorage.setItem(keyName, JSON.stringify(data));
  };

  getLocalStorage = (keyName) => {
    const data = EmbedController.isEmbedded ? window._tt?.hasTouredLocalStorage : localStorage.getItem(keyName);
    if (!data) {
      // if no value exists associated with the key, return null
      return null;
    }

    const item = JSON.parse(data);

    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item.ttl) {
      if (EmbedController.isEmbedded) EmbedController.sendLocalStorage("remove", { keyName: keyName });
      else localStorage.removeItem(keyName);
      return null;
    }

    // return data if not expired
    return item.value;
  };

  runProductTour = (e) => {
    const isTriggeredByHelpWidget = e.detail;

    if ((this.isSkipped || this.isReturningUser) && !isTriggeredByHelpWidget) return;

    window.isProductTourActive = true;

    //key, value, ttl (in days)
    this.setLocalStorage("rainbowPlayProductTour", true, 14);

    const { productTourStartIndex, delay } = this.productTourFactory(this.props.buildSectionStepperActiveIndex);

    this.productTourTimer = setTimeout(
      () => {
        this.setState({
          run: true,
          stepIndex: productTourStartIndex,
        });
      },
      isTriggeredByHelpWidget ? 0 : delay
    );
  };

  // gets called everytime joyride state changes
  handleJoyrideCallback = (data) => {
    const { status, type, action, index, step } = data;

    // disable once finished, skipped, on closed
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || [ACTIONS.CLOSE].includes(action)) {
      window.isProductTourActive = false;
      this.isSkipped = true;
      this.setState({ run: false });
    }

    // disable productTour once user completes productTourStep1
    if ([EVENTS.STEP_AFTER].includes(type) && step.id === "productTourStep2") {
      this.setState({ run: false });
    }

    // prevents Step 2 animation from playing until user completes Step 2 tour & stops tour from running
    if ([EVENTS.STEP_AFTER].includes(type) && step.id === "productTourStep4") {
      window.isProductTourActive = false;
      this.setState({ run: false });
    }

    // increment or decrement stepIndex manually
    if ([EVENTS.STEP_AFTER].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      this.setState({ stepIndex });
    }

    // console.log(data);
  };

  // returns which productTourStep to start on and start delay based on animation
  productTourFactory = (buildSectionStepperActiveIndex) => {
    switch (buildSectionStepperActiveIndex) {
      case 0:
        pushGTMAnalyticsEvent("Step1-Product-Tour-Appeared");
        return { productTourStartIndex: 0, delay: 5000 };
      case 1:
        return { productTourStartIndex: 3, delay: 1200 };
      case 2:
        return { productTourStartIndex: 5, delay: 1200 };
      default:
        return { productTourStartIndex: 0, delay: 5000 };
    }
  };

  render() {
    const { steps, run, stepIndex } = this.state;

    return (
      <Joyride
        callback={this.handleJoyrideCallback}
        steps={steps}
        stepIndex={stepIndex}
        hideBackButton={true}
        showSkipButton={true}
        hideCloseButton={true}
        run={run}
        continuous={true}
        disableScrolling={true}
        disableOverlayClose={true}
        disableScrollParentFix={true}
        styles={{
          options: {
            primaryColor: "rgba(5, 79, 174, 0.7)",
            zIndex: 10000,
            width: "415px",
          },
          buttonClose: {
            display: "none",
          },
        }}
      />
    );
  }
}
