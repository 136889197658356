import AlertSlackOfError from "../monitoring/AlertSlackOfError";
import EmbedController from "../embed/EmbedController";

// refreshes (/# URL) or reloads (same URL) the page
export default function refreshExperience(source, sessionId, isSimpleReload, isAutoReload) {
  if (EmbedController.isEmbedded) {
    AlertSlackOfError(
      source,
      `refreshExperience() fired: ${isSimpleReload ? "simpleReload" : "fullRefresh"} by ${isAutoReload ? "code" : "shopper"}`,
      sessionId
    );
    if (isSimpleReload) EmbedController.sendParentReloadRequest();
    else EmbedController.sendParentRefreshRequest();
  } else {
    if (!isSimpleReload) window.location.hash = "#/";
    window.location.reload();
  }
}
