import { makeAutoObservable } from "mobx";

class mobX {
  show = false;
  component = null;
  product = null;

  constructor() {
    makeAutoObservable(this);
  }

  showManager({ product, ...component }) {
    this.show = true;
    this.component = component;
    this.product = product;
  }

  hideManager() {
    this.show = false;
    this.component = null;
    this.product = null;
  }

  setComponent({ component }) {
    this.component = component;
  }
}

const XComponentsManager = new mobX();

export default XComponentsManager;
