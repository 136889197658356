import { useEffect, useRef, createRef, useCallback } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { useSpring, config } from "react-spring";
import { isMobile, useMobileOrientation } from "react-device-detect";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const SizeCard = ({ sizeDataStateObj, baseDataActiveIndex, detailsDataStateObj }) => {
  const { isLandscape, isPortrait } = useMobileOrientation();
  const drawer = useRef();
  const activeSizeCard = sizeDataStateObj.state.array[baseDataActiveIndex][sizeDataStateObj.state.activeIndex];
  const cardElementsRef = useRef(sizeDataStateObj.state.array[baseDataActiveIndex].map(() => createRef()));

  // used for scroll position
  useEffect(() => {
    drawer.current = !drawer.current && document.getElementById("drawer");
  }, []);

  const [y, setY] = useSpring(() => ({
    config: config.default,
    y: 0,
    onChange: ({ value }) => {
      drawer.current.scrollTop = value.y;
    },
  }));

  const sizesToDisplay = detailsDataStateObj.isDetailsActive ? [activeSizeCard] : sizeDataStateObj.state.array[baseDataActiveIndex];

  const handleScrollAnimation = () => {
    if (activeSizeCard._id) {
      const index = sizeDataStateObj.state.array[baseDataActiveIndex].findIndex((cardElement) => cardElement._id === activeSizeCard._id);
      const offset = index === 0 ? 250 : 55;
      const scrollDestination = cardElementsRef.current.find((ref) => ref.current.id === activeSizeCard._id).current.offsetTop - offset;
      if (scrollDestination > 0) setY({ y: scrollDestination });
    }
  };

  useEffect(() => {
    handleScrollAnimation();
  }, [detailsDataStateObj.isDetailsActive]);

  const handleContactSales = () => {
    document.dispatchEvent(new CustomEvent("ToggleCustomerSupport"));
    pushGTMAnalyticsEvent("Contact-Sales-Step-2-Click");
  };

  const handleSeeDetails = (item) => {
    detailsDataStateObj.setState({ isActive: !detailsDataStateObj.isDetailsActive, playSetType: item.animationDetails.playSetType });
    if (!detailsDataStateObj.isDetailsActive) {
      pushGTMAnalyticsEvent("See-Details-Step-2-Click");
    }
  };

  const handleCardClick = useCallback((index) => {
    if (detailsDataStateObj.isDetailsActive) return;

    sizeDataStateObj.setState(index);

    document.dispatchEvent(new CustomEvent("userClickedSizeCard"));
    pushGTMAnalyticsEvent("Step2-Card-Click");
  }, []);

  return sizesToDisplay.map((item, index) => (
    <Col id={item._id} ref={cardElementsRef.current[index]} xs={6} md={isMobile && isPortrait ? 6 : 10} key={item._id} className="px-2 px-lg-0 mx-auto">
      <Card
        className={`mx-auto mb-3 ${item.customData.cardColor} ${
          sizeDataStateObj.state.activeIndex === index || detailsDataStateObj.isDetailsActive ? "active" : ""
        }`}
        onClick={() => handleCardClick(index)}
      >
        <div className="d-flex frame mx-auto mb-1">
          {sizeDataStateObj.state.activeIndex === index || detailsDataStateObj.isDetailsActive ? (
            <img className="checkMark" alt="check mark" src="/images/checkMark.svg" />
          ) : null}
          <Card.Img variant="top" className="playGroundImage mx-auto mt-1" src={item.previewImg} />
        </div>
        <Card.Body className="py-1">
          <Card.Title className="text-center">{item.name}</Card.Title>
          {detailsDataStateObj.isDetailsActive && <Card.Text>{item.description}</Card.Text>}
          <div className={`d-flex justify-content-between ${!detailsDataStateObj.isDetailsActive && "mt-4"}`}>
            <button className={`mb-1 mx-2 ${item.customData.cardColor}`} onClick={() => handleContactSales()}>
              {" "}
              Contact Sales{" "}
            </button>
            <button className={`mb-1 mx-2 ${item.customData.cardColor}`} onClick={() => handleSeeDetails(item)}>
              {" "}
              {detailsDataStateObj.isDetailsActive ? "Back" : "See Details"}{" "}
            </button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  ));
};
