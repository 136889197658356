export default async function SendMessageToSlack(message) {
  let json = {
    text: message,
  };

  // send POST request to our slack webhook
  const slackResponse = await fetch(process.env.REACT_APP_SLACK_ALERT_WEBHOOK, {
    mode: "no-cors",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  });

  // const res = await slackResponse.json();

  // if (res.status == 200)
  //   return true;
  // else
  //   console.log(res);

  return false;
}
