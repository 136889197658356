import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import playSystemsSizes from "../playSystemsSizes.json";
import { buildSectionStepperAtom } from "./BuildSectionStepperData";
import { isNumber } from "lodash";
import { sizeParam } from "../../components/Routing/UrlDataController";

export const sizeAtom = atom({
  array: playSystemsSizes,
  activeIndex: 0,
});

export const update_size_index = atom(null);

export default function SizeData({ urlParamsObj, updateUrl }) {
  const [size, setSize] = useAtom(sizeAtom);
  const [activeIndex, setActiveIndex] = useAtom(update_size_index);

  // handle request from children to update the activeIndex (url)
  useEffect(() => {
    if (isNumber(activeIndex) && activeIndex !== size.activeIndex) {
      updateUrl(activeIndex);
      setActiveIndex(null);
    }
  }, [activeIndex]);

  useEffect(() => {
    // update state when url changes
    let newIndex = urlParamsObj[sizeParam]?.index;
    if (isNumber(newIndex) && newIndex !== size.activeIndex) {
      setSize({ array: playSystemsSizes, activeIndex: newIndex });
    }
  }, [urlParamsObj]);

  // Reset activeIndex to 0 when user goes back to step 1
  const [buildSectionStepper] = useAtom(buildSectionStepperAtom);
  useEffect(() => {
    if (buildSectionStepper.activeIndex === 0) setSize({ array: playSystemsSizes, activeIndex: 0 });
  }, [buildSectionStepper.activeIndex]);

  // useEffect(() => {
  //   console.log("&&& size UPDATE", size.activeIndex);
  // }, [size]);

  return null;
}
