import Button from "react-bootstrap/Button";
import { isSafari, isIE } from "react-device-detect";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const OverlayBadBrowsers = ({ setIsOverlayVisible }) => {
  const handleSafariClick = () => {
    setIsOverlayVisible(false);
    pushGTMAnalyticsEvent("Safari-Continue-Button-Click");
  };

  return (
    <>
      <div
        className="OverlayBadBrowsers d-flex justify-content-center align-items-center"
        style={{ backgroundColor: "white", width: "100%", height: "100%", position: "absolute", top: 0, zIndex: 100000 }}
      >
        <div className="OverlayBadBrowsersContainer d-flex flex-column justify-content-center align-items-center">
          <img className="mb-3" src="/images/full-logo.svg" />
          <h6 className="mb-3">
            {isSafari && (
              <>
                <b>Safari</b> causes <b>issues</b> with this site's <b>animations & graphics</b> that are out of our control. Please switch to the <b>Chrome</b>
                , <b>Firefox</b>, or <b>Edge</b> browser for a smoother experience.
              </>
            )}
            {isIE && (
              <>
                <b>Internet Explorer</b> doesn't support 3D websites like ours. Please switch to the <b>Chrome</b>, <b>Firefox</b>, or <b>Edge</b> browser.
              </>
            )}
          </h6>
          {isSafari && <Button onClick={() => handleSafariClick()}>Continue Anyways</Button>}
        </div>
      </div>
    </>
  );
};
