import { useEffect } from "react";
import { useAtom, atom } from "jotai";
import { buildSectionStepperAtom } from "./BuildSectionStepperData";

export const sizeInfoAtom = atom({
  isActive: false,
  playSetType: null,
});

export default function SizeInfoData() {
  const [, setSizeInfo] = useAtom(sizeInfoAtom);

  // Deactivate whenever user navigates to new step
  const [buildSectionStepper] = useAtom(buildSectionStepperAtom);
  useEffect(() => {
    setSizeInfo({ isActive: false, playSetType: null });
  }, [buildSectionStepper.activeIndex]);

  return null;
}
