import Container from "react-bootstrap/Container";
import { BuildSection } from "../BuildSection/BuildSection";
import { SelectCardContainer } from "../SelectCard/SelectCardContainer";
import { CardElement } from "../CardElement/CardElement";
import { DetailsContainer } from "../Details/DetailsContainer";
import Stepper from "../Stepper/Stepper";
import { Details } from "../Details/Details";
import { SceneLoadingScreen } from "../SceneLoadingScreen/SceneLoadingScreen";
import { HUD } from "../HUD/HUD";
import "./styles.scss";
import { WidgetsContainer } from "../Widgets/WidgetsContainer";
import { WidgetToggleSizeInfo } from "../Widgets/WidgetToggleSizeInfo";
import { WidgetCopyURL } from "../Widgets/WidgetCopyURL";
import { CustomLoader } from "../CustomLoader/CustomLoader";
import { sizeInfoAtom } from "../../data/atoms/SizeInfoData";
import GrabSceneIndicator from "../GrabSceneIndicator/GrabSceneIndicator";
import XComponentsManager from "../ComponentsSelector/XComponentsManager";
import LeftComponentDrawer from "../ComponentsSelector/components/LeftComponentDrawer";
import ProductTour from "../ProductTour/ProductTour";
import { WidgetCustomerSupport } from "../Widgets/WidgetCustomerSupport";
import { HeadersBuildSection } from "../BuildSection/HeadersBuildSection";
import { Navigation } from "../BuildSection/Navigation";
import { WidgetHelp } from "../Widgets/WidgetHelp";
import { useAtom } from "jotai";

export const BuilderUI = ({ baseDataStateObj, sizeDataStateObj, productDataStateObj, buildSectionStepperStateObj }) => {
  const [sizeInfo, setSizeInfo] = useAtom(sizeInfoAtom);

  const [featuresIsActive, specsIsActive, detailData] = detailFactory(
    buildSectionStepperStateObj.state.activeIndex,
    baseDataStateObj.state,
    sizeDataStateObj.state,
    productDataStateObj.state
  );

  return (
    <Container fluid className="BuilderUI p-0">
      <BuildSection sizeInfo={sizeInfo} baseData={baseDataStateObj.state} detailData={detailData}>
        <HeadersBuildSection headerLabel="Rainbow Play Studio" headerLineImgSrc="/images/headerLine.svg" />

        <span className="productTourHighlightNavigateSteps"></span>
        <Navigation buildSectionStepperStateObj={buildSectionStepperStateObj} />
        <Stepper buildSectionStepperStateObj={buildSectionStepperStateObj} />

        <SelectCardContainer isDetailsActive={sizeInfo.isActive}>
          <CardElement
            baseDataStateObj={baseDataStateObj}
            sizeDataStateObj={sizeDataStateObj}
            productDataStateObj={productDataStateObj}
            buildSectionStepperActiveIndex={buildSectionStepperStateObj.state.activeIndex}
            detailsDataStateObj={{ isDetailsActive: sizeInfo.isActive, setState: setSizeInfo }}
            mobX={XComponentsManager}
          />
        </SelectCardContainer>

        <DetailsContainer isDetailsActive={sizeInfo.isActive}>
          <CardElement
            baseDataStateObj={baseDataStateObj}
            sizeDataStateObj={sizeDataStateObj}
            productDataStateObj={productDataStateObj}
            buildSectionStepperActiveIndex={buildSectionStepperStateObj.state.activeIndex}
            detailsDataStateObj={{ isDetailsActive: sizeInfo.isActive, setState: setSizeInfo }}
          />
          <Details
            stepIndex={buildSectionStepperStateObj.state.activeIndex}
            detailData={detailData}
            featuresIsActive={featuresIsActive}
            specsIsActive={specsIsActive}
          />
        </DetailsContainer>
      </BuildSection>

      {/* covers up BuildSection and enables a shopper to swap out items on a playset*/}
      <LeftComponentDrawer mobX={XComponentsManager} />
      {/* Uses .shared-scene-sizing to mimic the scene's canvas. It's on top of it but "see through". This allows us to overlay UI on the scene  */}
      <HUD mobX={XComponentsManager}>
        <ProductTour buildSectionStepperActiveIndex={buildSectionStepperStateObj.state.activeIndex} />
        {/* loading screen used for the initial load - it covers the scene's canvas */}
        <SceneLoadingScreen buildSectionStepperActiveIndex={buildSectionStepperStateObj.state.activeIndex} />
        {/* loader for playsets in step 3 */}
        {buildSectionStepperStateObj.state.activeIndex === 2 && <CustomLoader />}

        {/* popup to inform the user to grab the scene to move camera or platform */}
        <GrabSceneIndicator buildSectionStepperActiveIndex={buildSectionStepperStateObj.state.activeIndex} />

        <WidgetsContainer>
          <WidgetCustomerSupport />
          <WidgetHelp
            buildSectionStepperActiveIndex={buildSectionStepperStateObj.state.activeIndex}
            isDetailsActive={sizeInfo.isActive}
            setDetails={setSizeInfo}
          />
          {buildSectionStepperStateObj.state.activeIndex > 0 && (
            <>
              <WidgetToggleSizeInfo
                baseData={baseDataStateObj.state}
                sizeData={sizeDataStateObj.state}
                isDetailsActive={sizeInfo.isActive}
                setDetails={setSizeInfo}
              />
              <WidgetCopyURL />
            </>
          )}
        </WidgetsContainer>
      </HUD>
    </Container>
  );
};

// returns which details (features or specs) are to be rendered and appropriate details data
const detailFactory = (buildSectionStepperActiveIndex, baseData, sizeData, productData) => {
  switch (buildSectionStepperActiveIndex) {
    case 0:
      return [true, false, { ...baseData, array: baseData.array[baseData.activeIndex] }];
    case 1:
      return [false, true, { ...sizeData, array: sizeData.array[baseData.activeIndex][sizeData.activeIndex] }];
    case 2:
      return [true, true, { ...productData, array: productData.array[baseData.activeIndex][sizeData.activeIndex][productData.activeIndex] }];
    default:
      return [true, false, { ...baseData, array: baseData.array[baseData.activeIndex] }];
  }
};
