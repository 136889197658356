import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export const Features = ({ detailData, isActive }) => {
  if (!isActive) return null;

  return (
    <>
      <Row className="g-0">
        <Col>
          <h3 className="text-center">Features</h3>
          <hr
            className="mx-auto"
            style={{
              transform: "rotate(-2deg)",
              filter: "drop-shadow(4px 4px 2px gray)",
              background: "linear-gradient(90deg, rgba(245,97,100,1) 0%, rgba(255,195,9,1) 100%)",
              width: 55,
              height: 3,
              opacity: 100,
              borderRadius: 10,
            }}
          />
        </Col>
      </Row>
      <Row className="g-0 px-2">
        {Object.keys(detailData.array.features).map((key, index) => (
          <Col xs={6} key={index} className="my-2">
            <div className="d-flex align-items-center justify-content-center">
              <img alt="feature icon" className="mx-1" src={detailData.array.features[key]} />
              <p className="m-0">{key}</p>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};
