import { atom } from "jotai";
import React from "react";
import ReactDOM from "react-dom";
import { ProductBuilder } from "./ProductBuilder/ProductBuilder";
import { UrlDataController, jsonToURI } from "./ProductBuilder/components/Routing/UrlDataController";
import EmbedController from "./embed/EmbedController";
import { HandleGeneralErrors } from "./monitoring/handleErrors";

// create Session Id for this runtime instance that can be used for us to identify errors
const { v4: uuidv4 } = require("uuid");
export const session_id = atom(uuidv4());

function beginExperience() {
  if (EmbedController.isEmbedded) {
    document.addEventListener("InitEmbed", reactRender);
  } else {
    if (window.location.host.includes("localhost") || window.location.host.includes("rainbow.tastetest3d")) {
      reactRender();
    } else {
      handleRedirectToNewUrl(); // if site's not embedded and it's not a test site, redirect the shopper to the new embedded site with same configuration
    }
  }
}

function reactRender() {
  ReactDOM.render(
    <React.StrictMode>
      <HandleGeneralErrors>
        <UrlDataController>
          <ProductBuilder />
        </UrlDataController>
      </HandleGeneralErrors>
    </React.StrictMode>,
    document.getElementById("react-root")
  );
}

beginExperience();

// This project used to use path based routing, but now uses hash based routing,
// so we need to handle redirects from old path based urls to new hash based urls
// since customers save their configurations via URL
function handleRedirectToNewUrl() {
  let hashPath;
  if (window.location.hash) {
    hashPath = window.location.hash;
  } else {
    // obtain vars from url path
    const path = window.location.pathname;
    const pathParams = path.split("/");
    const style = pathParams[1];
    const size = pathParams[2];
    const playset = pathParams[3];
    console.log("style", style);
    console.log("size", size);
    console.log("playset", playset);

    // convert to hash path
    hashPath = "#/";
    if (style) hashPath += `${style}/`;
    if (size) hashPath += `${size}/`;
    if (playset) hashPath += `${playset}/`;
    console.log("hashPath", hashPath);

    // convert query string params to items object and add it to hash path
    const queryString = window.location.search;
    console.log("queryString", queryString);
    const queryParams = new URLSearchParams(queryString);
    const itemsObj = {};
    const allowedParamKeys = [
      "slide_main",
      "slide_secondary",
      "slide_penthouse",

      "rockWall",

      "roof_main",
      "roof_penthouse",
      "roof_secondary",

      "clubhouse_base",
      "clubhouse_base_secondary",

      "swing_1",
      "swing_2",
      "swing_3_inside",
      "swing_3_outside",
      "swing_4",
      "swing_castle",
    ];
    for (const [key, value] of queryParams) {
      console.log(key, value);
      if (allowedParamKeys.includes(key)) {
        itemsObj[key] = value;
      }
    }
    console.log("itemsObj", itemsObj);
    if (Object.keys(itemsObj).length > 0) {
      hashPath += `${jsonToURI(itemsObj)}`;
    }
    console.log("hashPath", hashPath);
  }

  // redirect to updated experience and attach hash path
  if (window.location.host.includes("localhost")) {
    window.location.replace(`http://localhost:3000/embed-test.html${hashPath}`);
  } else {
    window.location.replace(`https://rainbowplay.com/builder${hashPath}`);
  }
}
