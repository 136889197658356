import { useEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Environment } from "@react-three/drei";

export const EnvironmentController = ({ buildSectionStepperActiveIndex }) => {
  // since there's a Suspense wrapping this component, this won't run until after all children components have loaded and rendered
  // signaling to the loading screen we're ready to go
  useEffect(() => {
    document.dispatchEvent(new CustomEvent("EnvironmentAssetsLoaded"));
  }, []);

  const shadowLight = useRef();

  /**
   * adjust gradient background to appear like a 3D skybox
   */
  const camera = useThree(({ camera }) => camera);
  const htmlEl = useRef(document.getElementsByClassName("scene-canvas-cover")[0]);
  const greyPercent = useRef(25);
  const prevCameraPosY = useRef(camera.position.y);
  useFrame(() => {
    // only run on step 3 or if camera.position.y has changed
    if (buildSectionStepperActiveIndex === 2 && roundPos(prevCameraPosY.current) !== Math.min(roundPos(camera.position.y), 8)) adjustFakeSkybox();
  });
  function adjustFakeSkybox() {
    // normalizing percentage between 25 - 100 depending on camera position (y) between 1.6 - 8
    greyPercent.current = (100 - 25) * ((Math.min(camera.position.y, 8) - 1.6) / (8 - 1.6)) + 25;
    htmlEl.current.style.background = `linear-gradient(0deg, rgb(130, 130, 130) 0%, rgb(130, 130, 130) ${greyPercent.current}%, rgb(80, 174, 229) ${Math.min(
      greyPercent.current + 75,
      125
    )}%)`;
    // keep track of previous camera position to help performance when camera is still
    prevCameraPosY.current = Math.min(roundPos(camera.position.y), 8);
  }
  function roundPos(num) {
    return Math.round(num * 100) / 100;
  }
  // reset background for others scenes
  useEffect(() => {
    if (buildSectionStepperActiveIndex != 2) htmlEl.current.style.background = null;
  }, [buildSectionStepperActiveIndex]);
  /**
   * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
   */

  return (
    <>
      <Environment
        background={false}
        files={"/textures/forest.hdr"}
        scene={undefined} // adds the ability to pass a custom THREE.Scene
      />

      <directionalLight
        ref={shadowLight}
        intensity={1}
        color={"white"}
        position={[0, 6, 0]}
        castShadow={buildSectionStepperActiveIndex === 2 ? true : false}
        shadow-mapSize-width={256}
        shadow-mapSize-height={256}
        shadow-camera-near={1}
        shadow-camera-far={10}
        shadow-camera-left={-5.5}
        shadow-camera-right={5.5}
        shadow-camera-top={5.5}
        shadow-camera-bottom={-5.5}
      />
      <ambientLight args={["#F9DEA4", 0.7]} />
    </>
  );
};
