import React from "react";
import { BuilderUI } from "./components/BuilderUI/BuilderUI";
import { BuilderScreen } from "./components/BuilderScreen/BuilderScreen";
import { Scene } from "./components/r3f/Scene";
import { OverlayBadBrowsers } from "./components/OverlayBadBrowsers/OverlayBadBrowsers";
import { buildSectionStepperAtom, update_buildSectionStepper_index } from "./data/atoms/BuildSectionStepperData.js";
import { baseAtom, update_base_index } from "./data/atoms/BaseData.js";
import { sizeAtom, update_size_index } from "./data/atoms/SizeData.js";
import { playsetAtom, update_playset_index } from "./data/atoms/PlaysetData.js";
import { LockOrientation } from "./components/LockOrientation/LockOrientation";
import { isMobile, isSafari, isIE } from "react-device-detect";
import { useAtom } from "jotai";
import "./styles.scss";
import { isNumber } from "lodash";

export const ProductBuilder = () => {
  const [buildSectionStepper] = useAtom(buildSectionStepperAtom);
  const [, setBuildSectionStepper] = useAtom(update_buildSectionStepper_index);

  const [baseData] = useAtom(baseAtom);
  const [, setBaseData] = useAtom(update_base_index);

  const [sizeData] = useAtom(sizeAtom);
  const [, setSizeData] = useAtom(update_size_index);

  const [playsetData] = useAtom(playsetAtom);
  const [, setPlaysetData] = useAtom(update_playset_index);

  const [isOverlayVisible, setIsOverlayVisible] = React.useState((!isMobile && isSafari) || isIE);

  return (
    <BuilderScreen className="BuilderScreen">
      {/* overlay to prevent users from viewing in certain orientation on mobile */}
      <LockOrientation imgSrc={"/images/full-logo.svg"} acceptedOrientation={"portrait"} />
      {/* ovelay to inform users of issues with safari on mac/iPad & stop them from using the site on IE */}
      {isOverlayVisible && <OverlayBadBrowsers isOverlayVisible={isOverlayVisible} setIsOverlayVisible={setIsOverlayVisible} />}
      {/* continue with regular experience */}
      {!isOverlayVisible && isNumber(buildSectionStepper.activeIndex) && (
        <>
          <BuilderUI
            baseDataStateObj={{ state: { ...baseData }, setState: setBaseData }}
            sizeDataStateObj={{ state: { ...sizeData }, setState: setSizeData }}
            productDataStateObj={{ state: playsetData, setState: setPlaysetData }}
            buildSectionStepperStateObj={{ state: buildSectionStepper, setState: setBuildSectionStepper }}
          />
          <Scene
            baseData={{ ...baseData }}
            sizeData={{ ...sizeData }}
            productData={{ ...playsetData }}
            buildSectionStepperActiveIndex={buildSectionStepper.activeIndex}
          />
        </>
      )}
    </BuilderScreen>
  );
};
